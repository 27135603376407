import React, { Component } from "react";
import { FaArrowRight } from "react-icons/fa";

class FirstTab extends Component {
  constructor(props) {
    super(props);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onSubmitHandler(e) {
    this.props.handleStepChange(2);
    e.preventDefault();
  }

  render() {
    const {
      inventionTitle,
      inventionArea,
      inventionDescription,
      question1,
    } = this.props.oldValues;

    return (
      <div className="wizard-tab">
        <form onSubmit={this.onSubmitHandler} className="needs-validation">
          <div className="row mb-2">
            <div className="col-7">
              <h4 className="text-left purple-text">Invention Overview:</h4>
            </div>
            <div className="col-5">
              <h4 className="text-right">Step 1 - 4</h4>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="inventionTitle" className="form-label">
              The title of the invention is
            </label>
            <input
              value={inventionTitle}
              type="text"
              className={`form-control ${
                inventionTitle.length >= 10 ? "is-valid" : ""
              }`}
              id="inventionTitle"
              name="inventionTitle"
              maxLength={250}
              minLength={10}
              placeholder="(250 characters)"
              required={true}
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inventionArea" className="form-label">
              The invention relates to the following subject areas
            </label>
            <input
              value={inventionArea}
              type="text"
              className={`form-control ${
                inventionArea.length >= 10 ? "is-valid" : ""
              }`}
              maxLength={250}
              minLength={10}
              id="inventionArea"
              name="inventionArea"
              placeholder="(250 characters)"
              required={true}
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inventionDescription" className="form-label">
              Brief description of the invention
            </label>
            <input
              value={inventionDescription}
              type="text"
              className={`form-control ${
                inventionDescription.length >= 10 ? "is-valid" : ""
              }`}
              maxLength={250}
              minLength={10}
              id="inventionDescription"
              name="inventionDescription"
              placeholder="(250 characters)"
              required={true}
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="question1" className="form-label">
              Is this form being submitted to initiate patent filing process?
            </label>
            <textarea
              value={question1}
              className={`form-control ${question1 !== "" ? "is-valid" : ""}`}
              id="question1"
              name="question1"
              maxLength={250}
              required={true}
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="pt-2 input-group justify-content-end">
            <button type="submit" className="btn btn-danger">
              <span className="p-3">Continue</span>
              <FaArrowRight />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default FirstTab;
