import React, { Component } from "react";
import { isAuthenticated } from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import axios from "axios";
import rubbish from "../../images/rubbish.png";
import BaseComponent from "../core/BaseComponent";

class HackathonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentEntry: {},
      currentEntryDocs: [],
      isLoading: false,
    };
    this.handleEntryClick = this.handleEntryClick.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      axios
        .get(`${API}/hackathon`, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          this.setState({ items: res.data }, () => this.handleEntryClick(0));
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  handleEntryClick(index) {
    this.setState({ isLoading: true }, () => {
      axios
        .get(`${API}/hackathon/docs/${this.state.items[index].id}`, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          console.log(res);
          this.setState({ currentEntryDocs: res.data, isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    });
    this.setState({ currentEntry: this.state.items[index] });
  }

  handleFileDelete(fileId) {
    this.setState({ isLoading: true }, () => {
      axios
        .delete(`${API}/hackathon/docs/${fileId}`, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          console.log(res);
          let docs = this.state.currentEntryDocs.filter(
            (file) => file.id !== fileId
          );
          this.setState({ currentEntryDocs: docs, isLoading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    });
  }

  render() {
    const { items, currentEntry, currentEntryDocs, isLoading } = this.state;
    let itemsList = "You haven't submitted any inventions yet";

    if (typeof items === "object") {
      itemsList = items.map((item, index) => {
        return (
          <div
            key={item.id}
            style={{
              cursor: "pointer",
            }}
            onClick={() => this.handleEntryClick(index)}
            className={`mx-2 px-2 mb-2 py-2 border rounded alert ${
              currentEntry.id === item.id ? "alert-secondary" : ""
            } w-100 `}
          >
            <span className="font-weight-bold mr-2">Team Name: </span>
            {item.teamName}
            <br />
            <span className="font-weight-bold mr-2">Submitted By: </span>
            {item.userEmail}
          </div>
        );
      });
    }
    let teamMemberDiv = "No team members added...";
    if (typeof currentEntry.teamMembers === "object") {
      teamMemberDiv = currentEntry.teamMembers.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              borderLeftWidth: "2px",
              borderLeftColor: "black",
              borderLeftStyle: "solid",
            }}
            className="py-2 px-2 my-2 alert-danger"
          >
            <b>Name:</b> {item.name} | <b>Email:</b> {item.email} |
            <b> Phone:</b> {item.phone}
          </div>
        );
      });
    }
    let docsView = currentEntryDocs.map((item) => {
      return (
        <div
          key={item.id}
          style={{
            borderLeftWidth: "2px",
            borderLeftColor: "black",
            borderLeftStyle: "solid",
          }}
          className="py-2 px-2 my-2 alert-info"
        >
          <span>
            {item.title} |{" "}
            <a href={`${API}/hackathon/docs/download/${item.id}`}>Download</a>
          </span>
          <span className="float-right">
            <img
              src={rubbish}
              onClick={() => this.handleFileDelete(item.id)}
              alt="Delete"
              className="p-0 m-0"
              title="Delete File"
              style={{
                verticalAlign: "top",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      );
    });

    return (
      <BaseComponent isLoading={isLoading}>
        <div
          className="container"
          style={{
            boxShadow:
              "2px 2px 14px 0 rgb(0 0 0 / 22%), -8px -8px 18px 0 rgb(255 255 255 / 55%)",
            borderRadius: "25px",
          }}
        >
          <div className="mt-4 row justify-content-md-center border-bottom">
            <div className="col-md-10 pt-3 pb-2 bg-white">
              <h2 id="heading" className="text-center">
                Hackathon Submissions
              </h2>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div
              className="col-md-4 border-bottom bg-white pt-2 table-hover"
              style={{ overflowY: "scroll", height: "470px" }}
            >
              <div className="row">{itemsList}</div>
            </div>
            <div
              className="col-md-8 border-bottom bg-white border-left pt-2"
              style={{ overflowY: "scroll", height: "470px" }}
            >
              <div>
                <form>
                  <h4 className="text-center">
                    <u>Entry Details</u>
                  </h4>
                  <div className="form-group mt-4">
                    <label>
                      <b>Submitted by : </b>
                    </label>{" "}
                    <i>{currentEntry.userEmail}</i>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Team Name</label>
                    <input
                      type="text"
                      value={currentEntry.teamName}
                      className="form-control"
                      aria-describedby="emailHelp"
                      readOnly={true}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Idea Details</label>
                    <textarea
                      value={currentEntry.ideaDetails}
                      className="form-control"
                      aria-describedby="emailHelp"
                      readOnly={true}
                    />
                  </div>
                  <hr />
                  <h5>Team Members</h5>
                  <div>{teamMemberDiv}</div>
                  <hr />
                  <h5>Uploaded Files</h5>
                  {currentEntryDocs.length > 0
                    ? docsView
                    : "No files were uploaded..."}
                  <hr />
                </form>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default HackathonList;
