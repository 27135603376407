import React from "react";
import BaseComponent from "../core/BaseComponent";

function NotFound() {
  return (
    <BaseComponent>
      <h1>404 - File Not Found</h1>
    </BaseComponent>
  );
}

export default NotFound;
