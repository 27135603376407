import React, { Component } from "react";
import { isAuthenticated } from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Loader from "../core/Loader";
import BaseComponent from "../core/BaseComponent";

class InventionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      items: [],
      selectedInvention: {},
      active: 0,
      isLoading: false,
      isEditable: false,
      enableSubmitButton: false,
      serverMessage: "",
      isError: false,
    };
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      axios
        .get(`${API}/inventions`, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          this.setState({ items: res.data, isLoading: false });
          if (res.data.length !== 0)
            this.setState({ selectedInvention: res.data[0] });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    });
  }

  handleEdit(e) {
    console.log(e.data);
  }

  render() {
    const { items, isLoading } = this.state;

    const groupRowRendererParams = {
      // puts a checkbox onto each group row
      checkbox: true,
    };

    const CellRender = (params) => {
      return (
        <button
          onClick={() =>
            this.props.history.push(`/inventions/edit/${params.value}`)
          }
          type="button"
          className="btn btn-danger btn-sm"
        >
          <FaEdit className="mb-1" />
          <span className="ml-1">Edit</span>
        </button>
      );
    };

    let frameworkComponents = {
      cellRender: CellRender,
    };

    return (
      <BaseComponent isLoading={isLoading}>
        <div className="container-fluid pt-4">
          <div
            className="ag-theme-alpine"
            style={{ height: 450, width: "100%" }}
          >
            <AgGridReact
              rowData={items}
              rowSelection="multiple"
              toolPanel="columns"
              enableValue={true}
              enableRowGroup={true}
              enablePivot={true}
              groupUseEntireRow={true}
              suppressSizeToFit={true}
              groupRowRendererParams={groupRowRendererParams}
              frameworkComponents={frameworkComponents}
            >
              <AgGridColumn headerName="Invention">
                <AgGridColumn
                  field="id"
                  resizable={true}
                  headerName="Action"
                  width={90}
                  cellRenderer="cellRender"
                ></AgGridColumn>
                <AgGridColumn
                  field="inventionTitle"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  checkboxSelection={true}
                ></AgGridColumn>
                <AgGridColumn
                  field="inventionArea"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="closed"
                ></AgGridColumn>
                <AgGridColumn
                  field="inventionDescription"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="closed"
                ></AgGridColumn>
              </AgGridColumn>
              <AgGridColumn headerName="Invention Details">
                <AgGridColumn
                  field="question1"
                  sortable={true}
                  filter={true}
                  resizable={true}
                ></AgGridColumn>
                <AgGridColumn
                  field="question2"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="open"
                ></AgGridColumn>
                <AgGridColumn
                  field="question3"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="open"
                ></AgGridColumn>
                <AgGridColumn
                  field="question4"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="open"
                ></AgGridColumn>
                <AgGridColumn
                  field="question5"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="open"
                ></AgGridColumn>
                <AgGridColumn
                  field="question6"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="open"
                ></AgGridColumn>
                <AgGridColumn
                  field="question7"
                  sortable={true}
                  filter={true}
                  resizable={true}
                  columnGroupShow="open"
                ></AgGridColumn>
              </AgGridColumn>
              <AgGridColumn headerName="Date">
                <AgGridColumn
                  field="submittedDate"
                  sortable={true}
                  filter="agDateColumnFilter"
                ></AgGridColumn>
                <AgGridColumn
                  field="lastUpdatedDate"
                  sortable={true}
                  filter="agDateColumnFilter"
                ></AgGridColumn>
              </AgGridColumn>
              <AgGridColumn headerName="User">
                <AgGridColumn
                  field="userName"
                  sortable={true}
                  filter={true}
                ></AgGridColumn>
              </AgGridColumn>
            </AgGridReact>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default InventionList;
