import React from "react";

function HackathonTerms() {
  return (
    <div className="px-3">
      <h4>CODE OF CONDUCT</h4>
      <div className="px-4">
        <ul>
          <li className="mb-2">
            Ideas submitted by the teams need not be fully implemented; however,
            the submission should be functional so that the judges can review
            it.
          </li>
          <li className="mb-2">
            Teams should avoid submissions that are clearly off topic compared
            to the assigned challenge.
          </li>
          <li className="mb-2">
            Teams can of course gain advice and support from organizers,
            volunteers and others.
          </li>
          <li className="mb-2">
            Intellectual Property: Your Submission and all components thereof
            must: (a) be your (or your Team) original work product; (b) be
            solely owned by you or your Team with no other person or entity
            having any right or interest in it; and (c) not violate the
            intellectual property rights or other rights, including but not
            limited to copyright, trademark, patent, contract, and/or privacy
            rights, of any other person or entity.
          </li>
          <li className="mb-2">
            By entering the IP hackathon you represent, warrant, and agree that
            your submission meets the requirements and Legasis will not be
            obliged to answer or legally bound for any instance of third party
            rights violation.
          </li>
          <li className="mb-2">
            Teams have to respect the other participants and their opinions.
          </li>
          <li className="mb-2">
            Teams should not use expressions that incite violence or forms of
            discrimination, obscenity, or defamation.
          </li>
          <li className="mb-2">
            Teams should avoid content that is offensive, vulgar, defamatory, or
            that violates privacy or is otherwise against applicable law, as
            well as advertising content or content that concerns political or
            religious views or other such ideologies.
          </li>
          <li className="mb-2">
            All prizes are to be shared between all team members.
          </li>
          <li className="mb-2">
            Teams can be disqualified from the competition at the
            organizers&#39; discretion. Reasons might include but are not
            limited to breaking the Competition Rules, breaking the Code of
            Conduct, or other unsporting behaviour.
          </li>
        </ul>
      </div>     
      <hr />
      <h4>VERIFICATION OF POTENTIAL WINNERS</h4>
      <div className="px-4">
        <strong>
          THE AWARD OF A PRIZE TO A POTENTIAL WINNER IS SUBJECT TO VERIFICATION
          OF THE IDENTITY, QUALIFICATIONS, AND ROLE OF THE POTENTIAL WINNER IN
          THE CREATION OF THE SUBMISSION. &nbsp;
          <br />
          <br />
          The final decision to designate a winner shall be made by Legasis.
        </strong>
      </div>
      <hr />
      <h4>GENERAL CONDITIONS</h4>
      <div className="px-4">
        <ul>
          <li className="mb-2">
            Legasis reserves the right, in their sole discretion, to cancel,
            suspend and/or modify the IP Hackathon, or any part of it, in the
            event of a technical failure, fraud, or any other factor or event
            that was not anticipated or is not within their control.
          </li>
          <li className="mb-2">
            Legasis reserve the right in their sole discretion to disqualify any
            individual or team if it finds to be actually or presenting the
            appearance of tampering with the operations of the IP. Hackathon or
            to be acting in violation of these official rules or in a manner
            that is inappropriate, unsportsmanlike, not in the best interests of
            this IP Hackathon, or a violation of any applicable law or
            regulation.
          </li>
          <li className="mb-2">
            The terms and conditions of the IP Hackathon are subject to change
            at any time, Legasis will update the participants the amended terms
            and conditions through email.
          </li>
          <li className="mb-2">
            If at any time prior to the deadline, any participant believes that
            any term or condition is unclear or ambiguous, they must submit a
            written request for clarification to <i>kamal.k@legasis.in</i>
          </li>
          <li className="mb-2">
            By participating in the IP Hackathon you consent to the use of
            personal information about you, if you are a winner. Such personal
            information includes, but is not limited to, your name, likeness,
            photograph, voice, opinions, comments and hometown. It may be used
            in any existing or newly created media, worldwide without further
            payment or consideration or right of review, unless prohibited by
            law. Authorized use includes advertising and promotional purposes.
          </li>
          <li className="mb-2">
            The duration of your consent is for a period of one year following
            the conclusion of the IP Hackathon. This consent applies, as
            applicable, to all the participants.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HackathonTerms;
