import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LoginComponent from "./components/public/LoginComponent";
import RegisterUser from "./components/public/RegisterUser";
import InventionWizard from "./components/inventions/InventionWizard";
import NotFound from "./components/public/NotFound";
import InventionList from "./components/inventions/InventionList";
import AllInventionList from "./components/inventions/AllInventionList";
import AdminRoute from "./auth/helper/AdminRoute";
import PrivateRoute from "./auth/helper/PrivateRoute";
import CommonRoutes from "./auth/helper/CommonRoutes";
import HackathonForm from "./components/hackathon/HackathonForm";
import UserList from "./components/user/UserList";
import HackathonList from "./components/hackathon/HackathonList";
import UserProfile from "./components/user/UserProfile";
import EditInvention from "./components/inventions/EditInvention";
import ZoomRegistration from "./components/public/ZoomRegistration";
import SearchPatents from "./components/inventions/SearchPatents";
import Knowledge from "./components/Knowlegde";
import NavBar from "./components/core/NavBar";

const Routes = () => {
	return (
		<BrowserRouter>
		
			<Switch>
				<Route
					path="/"
					exact
					exact component={LoginComponent}
					//render={() => (window.location = "https://legasis.in")}
				/>
				<CommonRoutes path="/login" exact component={LoginComponent} />
				<CommonRoutes path="/register" exact component={RegisterUser} />
				<CommonRoutes path="/register/ipr-event" exact component={ZoomRegistration} />
				<PrivateRoute path="/knowledge" exact component={Knowledge} />
				<PrivateRoute
					path="/hackathon/case-study"
					exact
					component={HackathonForm}
				/>

				<PrivateRoute path="/" exact component={InventionWizard} />
				<PrivateRoute
					path="/inventions/my"
					exact
					component={InventionList}
				/>
				<PrivateRoute
					path="/inventions/all"
					exact
					component={AllInventionList}
				/>
				<PrivateRoute
					path="/inventions/new"
					exact
					component={InventionWizard}
				/>
				<PrivateRoute
					path="/user/profile"
					exact
					component={UserProfile}
				/>

				<AdminRoute path="/" exact component={InventionList} />
				<AdminRoute
					path="/inventions"
					exact
					component={InventionList}
				/>
				<AdminRoute
					path="/inventions/new"
					exact
					component={InventionWizard}
				/>
				<AdminRoute
					path="/inventions/edit/:id"
					exact
					component={EditInvention}
				/>
				<AdminRoute
					path="/users"
					exact
					component={UserList}
				/>
				<AdminRoute
					path="/hackathon/all"
					exact
					component={HackathonList}
				/>
				<AdminRoute
					path="/search"
					exact
					component={SearchPatents}
				/>

				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
