import React, { Component } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

class SecondTab extends Component {
  constructor(props) {
    super(props);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onSubmitHandler(e) {
    this.props.handleStepChange(3);
    e.preventDefault();
  }

  render() {
    const { question2, question3, question4 } = this.props.oldValues;
    return (
      <div className="wizard-tab">
        <form onSubmit={this.onSubmitHandler} className="needs-validation">
          <div className="row mb-2">
            <div className="col-7">
              <h4 className="text-left purple-text">Filling Details:</h4>
            </div>
            <div className="col-5">
              <h4 className="text-right">Step 2 - 4</h4>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="question2" className="form-label">
              Are you submitting this form to report a knowhow which you are
              unsure if it should be patented and therefore need advise?
            </label>
            <textarea
              value={question2}
              className={`form-control ${question2 !== "" ? "is-valid" : ""}`}
              id="question2"
              name="question2"
              maxLength={250}
              required={true}
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="question3" className="form-label">
              If yes, where do you think the patent needs to be filed?
            </label>
            <textarea
              value={question3}
              className={`form-control ${question3 !== "" ? "is-valid" : ""}`}
              id="question3"
              name="question3"
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="question4" className="form-label">
              Simple Indian patent or PCT?
            </label>
            <textarea
              value={question4}
              className={`form-control ${question4 !== "" ? "is-valid" : ""}`}
              id="question4"
              name="question4"
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="pt-2 input-group justify-content-between">
            <button
              onClick={() => this.props.handleStepChange(1)}
              type="button"
              className="btn btn-danger"
            >
              <FaArrowLeft />
              <span className="p-3">Go Back</span>
            </button>
            <button type="submit" className="btn btn-danger">
              <span className="p-3">Continue</span>
              <FaArrowRight />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default SecondTab;
