import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import BaseComponent from "../core/BaseComponent";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      filteredItems: [],
      selectedUser: {},
      currentTab: "ROLE_USER",
      selectedKey: "",
      isLoading: false,
      ajaxCalling: false,
      modalShow: false,
      modalDeleteShow: false,
      selectedKey: "",
      firstName: "",
      lastName: "",
      emailId: "",
      role: "",
      ajaxResult: false,
      serverResponse: "",
    };
    this.handleOpenDeleteDailog = this.handleOpenDeleteDailog.bind(this);
    // this.handleTabClick = this.handleTabClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getAllUsers = this.getAllUsers.bind(this);
    this.createLegasisUser = this.createLegasisUser.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.filterHandler = this.filterHandler.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.getAllUsers();
    });
  }

  getAllUsers() {
    axios
      .get(`${API}/users`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + isAuthenticated(),
        },
      })
      .then((res) => {
        this.setState(
          {
            isLoading: false,
            items: res.data,
            selectedUser: res.data[0],
          },
          () => this.filterHandler(this.state.currentTab)
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
    console.log(this.state.selectedKey, "selectedKey");
  }

  handleCardClick(emailId) {
    axios
      .get(`${API}/users/${emailId}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + isAuthenticated(),
        },
      })
      .then((res) => {
        this.setState({ selectedUser: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  filterHandler(currentTab) {
    let newList = this.state.items.filter((item) => item.role === currentTab);
    this.setState({
      filteredItems: newList,
      selectedKey: currentTab,
      currentTab: currentTab,
    });
  }

  handleOpenDeleteDailog(item) {
    this.setState({
      modalDeleteShow: true,
      selectedUser: item,
    });
  }

  handleDelete() {
    this.setState({ isLoading: true }, () => {
      axios
        .delete(`${API}/users/${this.state.selectedUser.userId}`, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          this.getAllUsers("Customers");
          this.setState({
            modalDeleteShow: false,
            filteredItems: this.state.items.filter(
              (item) => item.role === this.state.currentTab
            ),
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    });
  }

  createLegasisUser(e) {
    e.preventDefault();
    this.setState({ ajaxCalling: true }, () => {
      axios
        .post(
          `${API}/users/add/legasis-user`,
          {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            emailId: this.state.emailId,
            role: this.state.role,
          },
          {
            headers: {
              Authorization: "Bearer " + isAuthenticated(),
            },
          }
        )
        .then((res) => {
          this.setState({
            ajaxCalling: false,
            ajaxResult: res.data.result,
            serverResponse: res.data.message,
          });
        })
        .catch((err) => {
          this.setState({
            ajaxCalling: false,
            ajaxResult: "failed",
            serverResponse: "Something went wrong! Please try again.",
          });
          console.log(err);
        });
    });
  }

  handleModalClose() {
    let tab = this.state.role === "" ? this.state.currentTab : this.state.role;
    this.setState(
      {
        modalShow: false,
        modalDeleteShow: false,
        emailId: "",
        firstName: "",
        lastName: "",
        role: "",
        isLoading: true,
        currentTab: tab,
      },
      () => {
        this.getAllUsers();
      }
    );
  }

  render() {
    const {
      items,
      isLoading,
      selectedKey,
      modalShow,
      modalDeleteShow,
      ajaxCalling,
      firstName,
      lastName,
      emailId,
      role,
      ajaxResult,
      serverResponse,
      filteredItems,
    } = this.state;

    return (
      <BaseComponent isLoading={isLoading}>
        <div
          className="container px-5"
          style={{
            boxShadow:
              "2px 2px 14px 0 rgb(0 0 0 / 22%), -8px -8px 18px 0 rgb(255 255 255 / 55%)",
            borderRadius: "25px",
          }}
        >
          <div className="mt-4 row text-center py-3">
            <div className="col-8 px-0">
              <Nav
                justify
                variant="tabs"
                activeKey={this.state.currentTab}
                defaultActiveKey={this.state.currentTab}
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="ROLE_BACKOFFICE_USER"
                    onClick={() => this.filterHandler("ROLE_BACKOFFICE_USER")}
                  >
                    Legasis Users
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="ROLE_ADMIN"
                    onClick={() => this.filterHandler("ROLE_ADMIN")}
                  >
                    Admin Users
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="ROLE_USER"
                    onClick={() => this.filterHandler("ROLE_USER")}
                  >
                    Customer Users
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-4 px-0">
              <span className="float-right">
                <button
                  value="Add Legasis User"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.setState({ modalShow: true })}
                >
                  Add Legasis User
                </button>
              </span>
            </div>
          </div>
          <div className="row overflow-auto table-responsive-lg">
            <table className="mb-5 table table-hover table-bordered">
              <thead className="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Hackathon Registration</th>
                  <th scope="col">IP Event Registration</th>
                  <th scope="col">Company</th>
                  <th scope="col">Registered Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {filteredItems.length > 0 &&
                  filteredItems.map((user, index) => {
                    if (typeof filteredItems === "object") {
                      return (
                        <tr key={user.userId}>
                          <th scope="row">{index + 1}</th>
                          <td className="w-25">
                            {user.firstName + " " + user.lastName}
                          </td>
                          <td>
                            <i>{user.emailId}</i>
                          </td>
                          <td className="w-10">{user.phoneNumber}</td>
                          <td className="w-5">
                            {user.isHackathon ? "Yes" : "No"}
                          </td>
                          <td className="w-5">
                            {user.isIPREvent ? "Yes" : "No"}
                          </td>
                          <td className="w-10">{user.company}</td>
                          <td className="w-10">{user.registeredDate}</td>
                          <td className="w-10">
                            <div className="d-flex">
                              <button className="btn btn-sm btn-primary mr-1">
                                <FaEdit className="mb-1 ml-1" />
                              </button>
                              {user.role !== "ROLE_ADMIN" && (
                                <button
                                  onClick={() =>
                                    this.handleOpenDeleteDailog(user)
                                  }
                                  className="btn btn-sm btn-danger"
                                >
                                  <FaTrash className="mb-1" />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    }

                    // itemsList
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={modalShow} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create Legasis User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={this.createLegasisUser}
              className="needs-validation"
            >
              <div className="form-group row">
                <label htmlFor="emailId" className="col-sm-3 col-form-label">
                  Email Id:
                </label>
                <div className="col-sm-9">
                  <input
                    value={emailId}
                    type="email"
                    className={`form-control`}
                    id="emailId"
                    name="emailId"
                    required={true}
                    onChange={(event) =>
                      this.setState({ emailId: event.target.value })
                    }
                  />
                  <div className={"invalid-feedback"}>
                    Please provide a valid email address
                  </div>
                  <div className="valid-feedback">OK!</div>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="firstName" className="col-sm-3 col-form-label">
                  First Name:
                </label>
                <div className="col-sm-9">
                  <input
                    value={firstName}
                    type="text"
                    className={`form-control`}
                    id="firstName"
                    name="firstName"
                    required={true}
                    onChange={(event) =>
                      this.setState({ firstName: event.target.value })
                    }
                  />
                  <div className={"invalid-feedback"}>
                    First name is required!
                  </div>
                  <div className="valid-feedback">Looks Good!</div>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="lastName" className="col-sm-3 col-form-label">
                  Last Name:
                </label>
                <div className="col-sm-9">
                  <input
                    value={lastName}
                    type="text"
                    className={`form-control`}
                    id="lastName"
                    name="lastName"
                    required={true}
                    onChange={(event) =>
                      this.setState({ lastName: event.target.value })
                    }
                  />
                  <div className={"invalid-feedback"}>
                    Last name is required!
                  </div>
                  <div className="valid-feedback">Looks Good!</div>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="password" className="col-sm-3 col-form-label">
                  Password:
                </label>
                <div className="col-sm-9">
                  <input
                    value="Password1"
                    type="text"
                    className={`form-control`}
                    id="password"
                    name="password"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="role" className="col-sm-3 col-form-label">
                  Role:
                </label>
                <div className="col-sm-9">
                  <select
                    value={role}
                    className="form-control"
                    name="role"
                    id="role"
                    required={true}
                    onChange={(event) =>
                      this.setState({ role: event.target.value })
                    }
                  >
                    <option value="">--- Select Role ---</option>
                    <option value="ROLE_BACKOFFICE_USER">
                      ROLE_BACKOFFICE_USER
                    </option>
                    <option value="ROLE_ADMIN">ROLE_ADMIN</option>
                  </select>
                </div>
              </div>
              <div className="row justify-content-center">
                <button type="submit" className="btn btn-primary">
                  {ajaxCalling ? "Creating User..." : "Create"}
                </button>
              </div>
              {serverResponse && (
                <div
                  className={`alert mt-3 ${
                    ajaxResult === "success" ? "alert-success" : "alert-danger"
                  }`}
                >
                  {serverResponse}
                </div>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className={`btn btn-info`} onClick={this.handleModalClose}>
              OK
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalDeleteShow} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you want to delete this user?<br></br>
              <b>
                <i>{this.state.selectedUser.emailId}</i>
              </b>{" "}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={`btn btn-secondary`}
              onClick={this.handleModalClose}
            >
              Cancel
            </button>
            <button className={`btn btn-primary`} onClick={this.handleDelete}>
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </BaseComponent>
    );
  }
}

export default withRouter(UserList);
