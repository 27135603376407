import { useState,useRef } from "react";
import { FaChevronRight } from "react-icons/fa";

function KnowledgeData({ question, answer }) {
    const [ active, setActive ] = useState("");
    const [ height, setHeight ] = useState("0px");
    const [ rotate, setRotate ] = useState("accordion__icon");

    const content = useRef(null);

    function toggleAccordion() {
        setActive(active === "" ? "active" : "");
        setHeight(active === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setRotate(active === "active" ? "accordion__icon" : "accordion__icon rotate")
    }
    return(
        <div className="accordion__section">
            <button className={`accordion__button ${active}`} onClick={toggleAccordion} >
                <p className="accordion__title">{question}</p>
                <FaChevronRight className={`${rotate}`} size="1.5rem" style={{color: "#777"}}/>
            </button>
            <div 
                ref={content}
                style={{ maxHeight: `${height}`}}
                className="accordion__content"
            >
                <div className="accordion__text" dangerouslySetInnerHTML={{__html:answer}}></div>
            </div>
        </div>
    );
}

export default KnowledgeData;