import React, { Component } from "react";
import { isAuthenticated } from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import axios from "axios";
import { Toast } from "react-bootstrap";
import { FaExclamationTriangle, FaUpload } from "react-icons/fa";
import Loader from "../core/Loader";
import BaseComponent from "../core/BaseComponent";

class SearchPatents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      titles: [],
      searchTitle: "",
      timer: undefined,
      selectedInvention: {},
      active: 0,
      isLoading: false,
      isEditable: false,
      showToast: false,
      toastMessage: "",
      isSearched: false,
      count: 1,
    };
    this.getPatentsByPatentTitle = this.getPatentsByPatentTitle.bind(this);
    this.getPatentTitles = this.getPatentTitles.bind(this);
    this.getStaticData = this.getStaticData.bind(this);
    this.getStaticTitles = this.getStaticTitles.bind(this);
  }

  componentDidMount() {
    let data = [
      {
        id: "607fc38e2c9f60d745a94bab",
        patentNumber: "9175690",
        patentDate: "11/3/2015",
        patentTitle: "Compressor",
        applicantCountry: "US",
        inventorFirstName: "Yves",
        inventorLastName: "Burgold",
        patentAbstract:
          "A compressor is disclosed, which has an inflow channel for guiding a compression medium into a compressor housing, a pressure chamber formed within the compressor housing, and a liner segment with nozzles that transport the compression medium from the pressure chamber to a rotor. The nozzles are formed in the liner segment as a plurality of nozzles arranged in a group in fan-like manner.",
      },
      {
        id: "607fc38e2c9f60d745a94edb",
        patentNumber: "7650871",
        patentDate: "1/26/2010",
        patentTitle:
          "Rotary compressor and expander, and rotary engine using the same",
        applicantCountry: "US",
        inventorFirstName: "Richard",
        inventorLastName: "See",
        patentAbstract:
          "A rotary device for use with compressible fluids comprises a first rotation element mounted to rotate about a first axis and a casing having a surface enclosing at least a part of the first rotation element. An elongate cavity of varying cross sectional area is defined between a surface of the first rotation element and the casing surface. The rotary device also comprises a number of second rotation elements mounted to rotate about respective second axes. Each second rotation element is mounted to project through the casing surface and cooperate with the first rotation element surface to divide the cavity into adjacent working portions. At least one on the Working portions defines a closed volume for a part of a cycle of the device. As the first and second rotation elements rotate, the volumes of the working portions vary. Each second rotation element comprises a number of projecting portions of varying radius about the respective second axis such that each projecting portion projects through the casing into the cavity by a varying amount to cooperate with the first rotation element surface.",
      },
      {
        id: "607fc38e2c9f60d745a95036",
        patentNumber: "7210301",
        patentDate: "5/1/2007",
        patentTitle:
          "Use of 1,1,1,3,3-pentafluorobutane as a refrigerant in a turbocompressor cooling system",
        applicantCountry: "US",
        inventorFirstName: "Christoph",
        inventorLastName: "Meurer",
        patentAbstract:
          "The use of 1,1,1,3,3-pentafluorobutane (R365mfc) as refrigerant in turbocompressor cooling systems, which, due to their construction, can achieve high outputs with a small space and material requirement. Such systems used in high-performance commercial and industrial refrigeration units.",
      },
      {
        id: "607fc38e2c9f60d745a95167",
        patentNumber: "6568914",
        patentDate: "5/27/2003",
        patentTitle: "Compressor with pulsation pressure reducing structure",
        applicantCountry: "US",
        inventorFirstName: "Hew Nam",
        inventorLastName: "Ahn",
        patentAbstract:
          "A swash plate type compressor used for the air conditioner of an automobile is presented. The compressor has a structure where pulsation pressure caused when refrigerants are compressed and discharged is reduced, thereby permitting noise at the time of driving to be substantially reduced. The compressor can embody the structure by distributing and discharging refrigerant that has been compressed by a plurality of pistons and discharged from a plurality of bores into at least two discharge holes, wherein a frequency of the pulsation pressure is increased in proportion to the number of the discharge holes, but the strength of the pulsation pressure is decreased in inverse proportion to the number of discharge holes. Therefore, the driving noise of the compressor that is formed in proportion to the strength of the pulsation pressure is considerably decreased.",
      },
      {
        id: "607fc38e2c9f60d745a953e2",
        patentNumber: "8985974",
        patentDate: "3/24/2015",
        patentTitle: "Concentric multi-stage vane compressor",
        applicantCountry: "US",
        inventorFirstName: "Shuzo",
        inventorLastName: "Tsutsumi",
        patentAbstract:
          "Disclosed is a vane compressor in which cylinders concentrically formed at the side of a rotor are eccentrically inserted in ring-shaped spaces between cylindrical parts concentrically formed at the side of a stator. A pair of radially extending vane attachment grooves is formed in the rotor, and vanes are slidably attached in the vane attachment grooves. Compression chambers the volumes of which repeatedly increase and decrease with each rotation of the rotor are concentrically formed in multiple stages by the cylindrical parts of the stator, the cylinders of the rotor, and comb-tooth parts of the vanes. It is possible to realize a vane compressor in which compression chambers can be concentrically arranged in multiple stages in a simple structure by suppressing increase in the number of components to the minimum level.",
      },
      {
        id: "607fc38e2c9f60d745a9556d",
        patentNumber: "7156622",
        patentDate: "1/2/2007",
        patentTitle: "Compressor blade for an aircraft engine",
        applicantCountry: "US",
        inventorFirstName: "Karl",
        inventorLastName: "Schreiber",
        patentAbstract:
          "A compressor blade for an aircraft engine includes a blade core made of a fiber compound material and a metallic enclosing structure. The enclosing structure is of a multi-part design and includes blanks (4, 5) attached to the blade core by a metallic weave (6) on the suction side and on the pressure side, with the two blanks being firmly connected at the aerodynamically shaped leading edge of the compressor blade by to a leading-edge former (3). Depending on the specific loads applied on the pressure side and on the suction side, welds attaching the blanks to the leading-edge former (8, 9) are offset relative to each other and the material thicknesses of the components of the enveloping structure are matched to each other.",
      },
      {
        id: "607fc38e2c9f60d745a956c8",
        patentNumber: "7142930",
        patentDate: "11/28/2006",
        patentTitle:
          "Disturbance estimated type control system, gas compressor control system and method of designing a disturbance estimated type control system",
        applicantCountry: "US",
        inventorFirstName: "Kenzou",
        inventorLastName: "Nonami",
        patentAbstract:
          "A disturbance estimated-type control system has a control object based on a mathematical model having of one of a state equation and a transfer function and prepared by system identification technology. A disturbance estimating device is based on an expansion system based on the mathematical model for estimating a disturbance of the control object in accordance with an input signal inputted into the control object and a detection signal detected from the control object. A compensating device is based on the mathematical model for compensating for a deviation between the detection signal and a target value and for outputting a corresponding control signal. A subtractor subtracts the control signal of the compensating device from a disturbance estimated value of the disturbance estimating device.",
      },
      {
        id: "607fc38f2c9f60d745a95e2c",
        patentNumber: "8628307",
        patentDate: "1/14/2014",
        patentTitle:
          "Proximity-sensor support, a compressor, a valve plate and a cooler",
        applicantCountry: "US",
        inventorFirstName: "Ingwald",
        inventorLastName: "Wollrath",
        patentAbstract:
          "One describes a support for a proximity sensor to sense the proximity of a piston of a compressor, a compressor proper, a valve plate of a compressor and a cooler provided with these pieces of equipment. One of the objectives of the present invention is achieved by means of a support for a proximity sensor for sensing the proximity of a piston of a compressor comprising a cylinder for the piston, the cylinder being associated to a valve plate, configuring a high-pressure region inside the cylinder and a low-pressure region outside the cylinder, the support comprising a base structure, a first connection end portion and a second connection end portion, the proximity sensor being associated to the high-pressure and the base structure extending as far as the low-pressure region.",
      },
    ];
    this.setState({
      items: data,
      selectedInvention: data[0],
    });
  }

  getStaticTitles(event) {
    this.setState((prevState, prevProps) => {
      return {
        searchTitle: event.target.value,
        titles: prevState.items.slice(prevState.items.length - prevState.count),
        count:
          event.target.value.length > prevState.items.length
            ? prevState.items.length
            : event.target.value.length,
      };
    });
  }

  getStaticData() {
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.setState({
          isLoading: false,
          isSearched: true,
        });
      }, 2000);
    });
  }

  getPatentTitles(event) {
    const getTitle = () => {
      axios
        .get(`${API}/patents/search?title=${event.target.value}`, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          if (res.data.length !== 0) {
            this.setState({
              titles: res.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (this.state.timer) {
      this.setState(
        {
          timer: clearTimeout(this.state.timer),
          searchTitle: event.target.value,
        },
        () => {
          console.log("After clearing --> ", typeof this.state.timer);
          this.setState({
            timer: setTimeout(getTitle, 100),
            searchTitle: event.target.value,
          });
        }
      );
    } else {
      console.log("timer is cleared");
      this.setState(
        { timer: setTimeout(getTitle, 100), searchTitle: event.target.value },
        () => {
          console.log(
            "After setting timer --> ",
            this.state.timer,
            typeof this.state.timer
          );
        }
      );
    }
  }

  getPatentsByPatentTitle() {
    if (this.state.searchTitle === "") {
      this.setState({
        showToast: true,
        toastMessage: "Please enter some text for your search criteria",
      });
      return;
    }
    this.setState({ isLoading: true }, () => {
      axios
        .get(`${API}/patents/get?title=${this.state.searchTitle}`, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          if (res.data.length !== 0) {
            this.setState({
              items: res.data,
              selectedInvention: res.data[0],
              isLoading: false,
              isSearched: true,
            });
          } else {
            this.setState({
              items: [],
              selectedInvention: {},
              showToast: true,
              toastMessage:
                "Sorry! we could not find any patents for your search criteria",
              isLoading: false,
              isSearched: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    });
  }

  toggleItems(position) {
    if (this.state.active === position) {
      this.setState({ active: null });
    } else {
      this.setState({
        active: position,
        isEditable: false,
        enableSubmitButton: false,
        selectedInvention: this.state.items[position],
      });
    }
  }

  colorChange(position) {
    if (this.state.active === position) {
      return "#e2e3e5";
    }
    return "";
  }

  render() {
    const {
      items,
      titles,
      searchTitle,
      selectedInvention,
      isLoading,
      isEditable,
      showToast,
      toastMessage,
      isSearched,
    } = this.state;

    let itemsList = "You haven't submitted any inventions yet";

    if (typeof items === "object") {
      itemsList = items.map((item, index) => {
        return (
          <div
            key={item.id}
            style={{ background: this.colorChange(index), cursor: "pointer" }}
            onClick={() => {
              this.toggleItems(index);
            }}
            className="ml-1 pl-2 my-2 py-2 border rounded"
          >
            <div>
              <span className="mr-2 font-weight-bold">Patent Title: </span>
              <span className="font-weight-light">{item.patentTitle}</span>
            </div>
            <div>
              <span className="mr-2 font-weight-bold">Patent Number: </span>
              <span className="font-weight-light">{item.patentNumber}</span>
            </div>
            <div>
              <span className="mr-2 font-weight-bold">Sector: </span>
              <span className="font-weight-light">{item.wipoSectorTitle}</span>
            </div>
            <div>
              <span className="mr-2 font-weight-bold">Field: </span>
              <span className="font-weight-light">{item.wipoFieldTitle}</span>
            </div>
          </div>
        );
      });
    }

    return (
      <BaseComponent isLoading={isLoading}>
        <div
          className="container"
          style={{
            boxShadow:
              "2px 2px 14px 0 rgb(0 0 0 / 22%), -8px -8px 18px 0 rgb(255 255 255 / 55%)",
            borderRadius: "25px",
          }}
        >
          <div className="my-4 row text-center p-3 border-bottom">
            <div className="col-2"></div>
            <div className="col-8">
              <h2 id="heading" className="text-center">
                Search Patents
              </h2>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <input
                    value={searchTitle}
                    type="text"
                    list="datalist"
                    placeholder="Search..."
                    className="form-control float-right"
                    autoFocus={true}
                    onChange={this.getPatentTitles}
                  />
                  <datalist id="datalist">
                    {titles.map((item, index) => (
                      <option key={item.id} value={item.patentTitle}></option>
                    ))}
                  </datalist>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-primary"
                    onClick={this.getPatentsByPatentTitle}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row border-bottom">
            <div
              className="col-md-5 border-right"
              style={{ overflowY: "scroll", height: "470px" }}
            >
              {isSearched && <div className="">{itemsList}</div>}
            </div>
            <div
              className="col-md-7 p-3"
              style={{ overflowY: "scroll", height: "470px" }}
            >
              {isSearched && (
                <div className="pt-1 px-2 rounded">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Patent Title</label>
                      <textarea
                        value={selectedInvention.patentTitle}
                        name="inventionTitle"
                        className="form-control"
                        aria-describedby="emailHelp"
                        disabled={!isEditable}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Patent Number</label>
                      <input
                        type="text"
                        value={selectedInvention.patentNumber}
                        readOnly={true}
                        className="form-control"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Patent Date</label>
                          <input
                            type="text"
                            value={selectedInvention.patentDate}
                            readOnly={true}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Applicant Country</label>
                          <input
                            type="text"
                            value={selectedInvention.applicantCountry}
                            readOnly={true}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="inventionArea">Patent Abstract</label>
                      <textarea
                        value={selectedInvention.patentAbstract}
                        name="inventionArea"
                        id="inventionArea"
                        className="form-control"
                        aria-describedby="emailHelp"
                        disabled={!isEditable}
                        rows="7"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Toast
            style={{
              position: "fixed",
              top: 25,
            }}
            show={showToast}
            onClose={() => this.setState({ showToast: false })}
            delay={3000}
            autohide
          >
            <Toast.Header className="alert-danger">
              <FaExclamationTriangle className="mr-2" />
              <strong className="mr-auto">Message</strong>
              <small>just now</small>
            </Toast.Header>
            <Toast.Body className="alert-danger">{toastMessage}</Toast.Body>
          </Toast>
        </div>
      </BaseComponent>
    );
  }
}

export default SearchPatents;
