import React, { Component } from "react";
import zoomReg from "../../images/zoomReg.jpg";

class ZoomRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row col-xs-12 col-sm-12">
        <div className="container">
          <div className="banner">
            <img src={zoomReg} style={{ width: "100%" }} />
          </div>
          <br />
          <br></br>
          <iframe
            src="https://us02web.zoom.us/webinar/register/WN_jxHXmlb5Taik-9gK2s3UHQ"
            style={{
              width: "100%",
              height: "1450px",
              border: "0",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
            title="Iframe Example"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default ZoomRegistration;
