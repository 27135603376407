import axios from "axios";
import { API } from "../../backend";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../auth/helper/AuthenticationService";
import React, { Component } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

class ThirdTab extends Component {
  constructor(props) {
    super(props);
    this.state={
      isNewSubmission: true
    }
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }
  

  onSubmitHandler(e) {
    this.props.submitForm();
    e.preventDefault();
  }



  render() {
    const { question5, question6, question7 } = this.props.oldValues;
    const {isNewSubmission} = this.state;
    {console.log(this.props,'props')}

    return (
      <div className="wizard-tab">
        <form onSubmit={this.onSubmitHandler} className="needs-validation">
          <div className="row mb-2">
            <div className="col-7">
              <h4 className="text-left purple-text">Other Details:</h4>
            </div>
            <div className="col-5">
              <h4 className="text-right">Step 3 - 4</h4>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="question5" className="form-label">
              If the know how is not patented, do you think this can be treated
              as trade secret? In case of trade secret, VP will share
              information on ‘need to know basis’ and under ‘obligation’ only.
            </label>
            <textarea
              value={question5}
              className={`form-control ${question5 !== "" ? "is-valid" : ""}`}
              id="question5"
              name="question5"
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="question6" className="form-label">
              Is this case related to any project listed on OPTIVA/ JIRA, if
              yes, what is the reference number?
            </label>
            <textarea
              value={question6}
              className={`form-control ${question6 !== "" ? "is-valid" : ""}`}
              id="question6"
              name="question6"
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="question7" className="form-label">
              Is this case related to any Innovation proposal on the EUREKA, if
              yes, what is the reference number?
            </label>
            <textarea
              value={question7}
              className={`form-control ${question7 !== "" ? "is-valid" : ""}`}
              id="question7"
              name="question7"
              onChange={this.props.handleInputChange}
            />
          </div>
          <React.Fragment>
                      <h5 style={{ color: "#4397C0" }}>
                        Select Files to Upload:
                      </h5>
                      <div className="custom-file mt-2 mb-4">
                        <input
                          type="file"
                          multiple={true}
                          className="custom-file-input border border-secondary"
                          id="validatedCustomFile"
                          required={true}
                          onChange={this.props.handleFileChange}
                          // disabled={!isEditable}
                          accept="image/*,.doc,.docx,.txt,.pdf,.word,.rtf,.odt"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="validatedCustomFile"
                        >
                          {isNewSubmission
                            ? "Choose files..."
                            : "Add More Files..."}
                        </label>
                        <small className="form-text float-left text-muted">
                          Only pdf, text files and images are supported
                        </small>
                        <span className="float-right">
                          ({this.props.selectedFiles.length} files selected)
                        </span>
                        <div className="invalid-feedback">
                          Please select a valid file format
                        </div>
                      </div>
                    </React.Fragment>
          <div className="pt-2 input-group justify-content-between">
            <button
              onClick={() => this.props.handleStepChange(2)}
              type="button"
              className="btn btn-danger"
            >
              <FaArrowLeft />
              <span className="p-3">Go Back</span>
            </button>
            <button type="submit" className="btn btn-danger">
              <span className="p-3">Continue</span>
              <FaArrowRight />
            </button>
          </div>
        </form>
       
      </div>
    );
  }
}

export default ThirdTab;
