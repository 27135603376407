import axios from "axios";
import React, { Component } from "react";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import rubbish from "../../images/rubbish.png";
import background from "../../images/background-image.jpg";
import { Modal, Toast } from "react-bootstrap";
import { FaEdit, FaPlus, FaStopCircle, FaUpload } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import Loader from "../core/Loader";
import BaseComponent from "../core/BaseComponent";
import HackathonTerms from "./HackathonTerms";
import HackathonEligibility from "./HackathonEligibility";

class HackathonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewSubmission: true,
      isEditable: true,
      entryId: "",
      caseStudy: "",
      teamName: "",
      teamMembers: [
        {
          name: "",
          email: "",
          phone: "",
        },
      ],
      ideaDetails: "",
      selectedFiles: [],
      uploadedDocs: [],
      isLoading: false,
      modalShow: false,
      termsShow: false,
      eligibilityShow: false,
      toastShow: false,
      toastMessage: "",
      serverMessage: "",
      isError: false,
      errors: {},
    };
    this.addTeamMember = this.addTeamMember.bind(this);
    this.removeTeamMember = this.removeTeamMember.bind(this);
    this.handleTeamMemberInputChange = this.handleTeamMemberInputChange.bind(
      this
    );
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormUpdate = this.handleFormUpdate.bind(this);
    this.showTermsAndConditions = this.showTermsAndConditions.bind(this);
    this.showEligibility = this.showEligibility.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      axios
        .get(`${API}/hackathon/${getCurrentUser().userId}`, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.setState({
              isNewSubmission: false,
              isEditable: false,
              entryId: res.data.id,
              caseStudy: res.data.caseStudy,
              teamName: res.data.teamName,
              teamMembers: res.data.teamMembers,
              ideaDetails: res.data.ideaDetails,
            });
            axios
              .get(`${API}/hackathon/docs/${res.data.id}`, {
                headers: {
                  Authorization: "Bearer " + isAuthenticated(),
                },
              })
              .then((res) => {
                console.log(res);
                this.setState({ uploadedDocs: res.data, isLoading: false });
              })
              .catch((err) => {
                this.setState({ isLoading: false });
                console.log(err);
              });
          } else {
            this.setState({
              isNewSubmission: true,
              isEditable: true,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    });
  }

  addTeamMember() {
    let team = [...this.state.teamMembers];
    team.push({
      name: "",
      email: "",
      phone: "",
    });
    this.setState({ teamMembers: team });
  }

  removeTeamMember(ind) {
    let team = this.state.teamMembers.filter((member, index) => index !== ind);
    this.setState({ teamMembers: team });
  }

  handleTeamMemberInputChange(event, index) {
    let inputName = event.target.name;
    let team = [...this.state.teamMembers];
    team[index][inputName] = event.target.value;
    this.setState({ teamMembers: team });
  }

  handleFileChange(event) {
    this.setState({ selectedFiles: event.target.files });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true, isError: false, errors: false });
    let isError = false;
    let errs = { ...this.state.errors };

    this.state.teamMembers.forEach((member, index) => {
      if (member.name === "") {
        errs["name" + index] = true;
        isError = true;
      }
      if (member.email === "") {
        errs["email" + index] = true;
        isError = true;
      }
      if (member.phone === "") {
        errs["phone" + index] = true;
        isError = true;
      }
    });

    if (this.state.caseStudy === "") {
      errs.caseStudy = true;
      isError = true;
    }

    if (this.state.teamName === "") {
      errs.teamName = true;
      isError = true;
    }

    if (this.state.ideaDetails === "") {
      errs.ideaDetails = true;
      isError = true;
    }

    if (isError) {
      this.setState({
        isError: isError,
        errors: errs,
        isLoading: false,
        modalShow: true,
        serverMessage: "Please fill all the required fields!",
      });
      return;
    }

    var formData = new FormData();
    formData.append("caseStudy", this.state.caseStudy);
    formData.append("userEmail", getCurrentUser().emailId);
    formData.append("teamName", this.state.teamName);
    formData.append("userId", getCurrentUser().userId);
    formData.append("teamMembers", JSON.stringify(this.state.teamMembers));
    formData.append("ideaDetails", this.state.ideaDetails);
    for (let i = 0; i < this.state.selectedFiles.length; i++) {
      formData.append(
        "selectedFiles",
        this.state.selectedFiles[i],
        this.state.selectedFiles[i].name
      );
    }

    axios
      .post(`${API}/hackathon`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + isAuthenticated(),
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({
          isLoading: false,
          modalShow: true,
          isError: false,
          serverMessage: "Your idea has been submitted successfully!",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          modalShow: true,
          isError: true,
          serverMessage: "Something went wrong!",
        });
      });
  }

  handleFormUpdate(event) {
    event.preventDefault();
    this.setState({ isLoading: true, isError: false, errors: false });
    let isError = false;
    let errs = { ...this.state.errors };

    this.state.teamMembers.forEach((member, index) => {
      if (member.name === "") {
        errs["name" + index] = true;
        isError = true;
      }
      if (member.email === "") {
        errs["email" + index] = true;
        isError = true;
      }
      if (member.phone === "") {
        errs["phone" + index] = true;
        isError = true;
      }
    });

    if (this.state.caseStudy === "") {
      errs.caseStudy = true;
      isError = true;
    }

    if (this.state.teamName === "") {
      errs.teamName = true;
      isError = true;
    }

    if (this.state.ideaDetails === "") {
      errs.ideaDetails = true;
      isError = true;
    }

    if (isError) {
      this.setState({
        isError: isError,
        errors: errs,
        isLoading: false,
        modalShow: true,
        serverMessage: "Please fill all the required fields!",
      });
      return;
    }

    var formData = new FormData();
    formData.append("caseStudy", this.state.caseStudy);
    formData.append("userEmail", getCurrentUser().emailId);
    formData.append("teamName", this.state.teamName);
    formData.append("userId", getCurrentUser().userId);
    formData.append("teamMembers", JSON.stringify(this.state.teamMembers));
    formData.append("ideaDetails", this.state.ideaDetails);
    for (let i = 0; i < this.state.selectedFiles.length; i++) {
      formData.append(
        "selectedFiles",
        this.state.selectedFiles[i],
        this.state.selectedFiles[i].name
      );
    }

    axios
      .put(`${API}/hackathon/${this.state.entryId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + isAuthenticated(),
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({
          isLoading: false,
          modalShow: true,
          isError: false,
          serverMessage: "Your submission has been updated successfully!",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          modalShow: true,
          isError: true,
          serverMessage: "Something went wrong!",
        });
      });
  }

  handleFileDelete(fileId) {
    axios
      .delete(`${API}/hackathon/docs/${fileId}`, {
        headers: {
          Authorization: "Bearer " + isAuthenticated(),
        },
      })
      .then((res) => {
        console.log(res);
        let docs = this.state.uploadedDocs.filter((file) => file.id !== fileId);
        this.setState({ uploadedDocs: docs, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  showTermsAndConditions() {
    this.setState({ termsShow: true });
  }

  showEligibility() {
    this.setState({ eligibilityShow: true });
  }

  render() {
    const {
      teamName,
      ideaDetails,
      isLoading,
      teamMembers,
      isEditable,
      isNewSubmission,
      uploadedDocs,
      selectedFiles,
      modalShow,
      termsShow,
      eligibilityShow,
      isError,
      serverMessage,
      errors,
    } = this.state;

    let teamMemberDiv = teamMembers.map((item, index) => {
      return (
        <div
          key={index}
          className="form-row p-3 my-3 mx-1 rounded justify-content-md-center bg-yellow"
        >
          <div className="col-md-4">
            <input
              value={item.name}
              type="text"
              className={`form-control ${
                errors["name" + index] ? "is-invalid" : ""
              }`}
              name="name"
              id={"name" + index}
              required={true}
              placeholder="Full Name"
              disabled={!isEditable}
              onChange={(event) =>
                this.handleTeamMemberInputChange(event, index)
              }
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Name is required!</div>
          </div>
          <div className="col-md-4">
            <input
              value={item.email}
              type="email"
              className={`form-control ${
                errors["email" + index] ? "is-invalid" : ""
              }`}
              name="email"
              id={"email" + index}
              required={true}
              placeholder="Email"
              disabled={!isEditable}
              onChange={(event) =>
                this.handleTeamMemberInputChange(event, index)
              }
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">
              Please provide a valid Email address!
            </div>
          </div>
          <div className="col-md-3">
            <input
              value={item.phone}
              type="tel"
              className={`form-control ${
                errors["phone" + index] ? "is-invalid" : ""
              }`}
              name="phone"
              id={"phone" + index}
              required={true}
              placeholder="Mobile"
              disabled={!isEditable}
              onChange={(event) =>
                this.handleTeamMemberInputChange(event, index)
              }
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Phone number is required!</div>
          </div>
          <div className="col-md-1 text-right">
            <button
              type="button"
              disabled={!isEditable}
              className="btn btn-light"
              onClick={() => this.removeTeamMember(index)}
            >
              <img
                src={rubbish}
                alt="Delete"
                className="p-0 m-0"
                title="Delete File"
                style={{
                  verticalAlign: "top",
                  cursor: "pointer",
                }}
              />
            </button>
          </div>
        </div>
      );
    });

    let docsView = uploadedDocs.map((item, index) => {
      return (
        <div
          key={item.id}
          style={{
            borderLeftWidth: "3px",
            borderLeftColor: "black",
            borderLeftStyle: "solid",
          }}
          className="alert alert-info"
        >
          <span>
            {item.title} |{" "}
            <a href={`${API}/hackathon/docs/download/${item.id}`}>Download</a>
          </span>
          {isEditable && (
            <span className="float-right">
              <a
                href="#"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => this.handleFileDelete(item.id)}
              >
                <img
                  src={rubbish}
                  alt="Delete"
                  className="p-0 m-0"
                  title="Delete File"
                  style={{
                    verticalAlign: "top",
                    cursor: "pointer",
                  }}
                />
              </a>
            </span>
          )}
        </div>
      );
    });

    return (
      <BaseComponent isLoading={isLoading}>
        <div className="back">
          <section className="p-4">
            <Carousel>
              <Carousel.Item>
                <div className="contents">
                  <div
                    style={{
                      border: "solid 1px #f89b25",
                      borderLeftWidth: "4px",
                      borderLeftColor: "#f89b25",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    className="alert-success text-center px-4"
                  >
                    <h5 className="mt-4">SMART HEALTH CARE: DIAGNOSIS AND MONITORING HEALTH PARAMETERS</h5>
                    <p className="mb-4">
                    In today’s hectic life the biggest challenge we face is mostly related to health care. Several new Smart health care devices have been introduced which has become a necessity for today’s generation. Let’s us all come together to build a smart, effective, accessible and hassle free way to diagnose and monitor health care.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="contents">
                  <div
                    style={{
                      border: "solid 1px #f89b25",
                      borderLeftWidth: "4px",
                      borderLeftColor: "#f89b25",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    className="alert-success text-center px-4"
                  >
                    <h5 className="mt-4">FUTURE OF MOBILITY: INNOVATING THE UNIMAGINABLE</h5>
                    <p className="mb-4">
                    When you were a child, did you ever imagine that we’d have flying cars by now? We are far from flying cars, however, there is a need to shift our future of mobility to environmental-friendly, connected, autonomous, and personalized commutes. The future of mobility is just not limited to vehicles, alternative fuel or hybrid fuel but also integrated with safety, technologically advanced modes of transportation and other digital/technology related to infrastructure for transportation. If you have an idea that can help us change our methods of transportation for the better, present it in the IP Hackathon to get one step closer.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="contents">
                  <div
                    style={{
                      border: "solid 1px #f89b25",
                      borderLeftWidth: "4px",
                      borderLeftColor: "#f89b25",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    className="alert-success text-center px-4"
                  >
                    <h5 className="mt-4">REDESIGN YOUR FAVORITE LOGO</h5>
                    <p className="mb-4">
                    When someone sees a capital ‘M’ written in Yellow, his mind directly understands that the brand is ‘McDonalds’. A trademark or a brand’s logo is its identity which helps to promote the brand and to stand out from its competitors. This is your chance to present your idea on how a brand can re-design their logo in a better way. We invite you to let your creativity flow by redesigning and revamping your favorite logo.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </section>
          <div
            style={{ background: "rgb(255, 255, 255, 0)" }}
            className="container"
          >
            <div className="row my-4 justify-content-md-center">
              <div
                style={{
                  background: "rgb(255, 255, 255, 0)",
                }}
                className="rounded-lg col-md-10"
              >
                {serverMessage && (
                  <div
                    className={`alert ${
                      isError ? "alert-danger" : "alert-success"
                    }`}
                  >
                    {serverMessage}
                  </div>
                )}

                <br />
                <form
                  onSubmit={this.handleFormSubmit}
                  className="w-100 needs-validation shadow-lg rounded bg-white px-5 py-4 mb-5"
                  noValidate={true}
                >
                  <div className="mt-2 mb-3">
                    <label htmlFor="caseSelect" className="form-label">
                      <h5 style={{ color: "#4397C0" }}>Select Case Study :</h5>
                    </label>
                    <select
                      id="caseSelect"
                      className={`form-control ${
                        errors.caseStudy ? "is-invalid" : ""
                      }`}
                      disabled={!isEditable}
                      value={this.state.caseStudy}
                      required={true}
                      onChange={(event) =>
                        this.setState({ caseStudy: event.target.value })
                      }
                    >
                      <option value="">-- Please Select --</option>
                      <option value="1">SMART HEALTH CARE: DIAGNOSIS AND MONITORING HEALTH PARAMETERS</option>
                      <option value="2">FUTURE OF MOBILITY: INNOVATING THE UNIMAGINABLE</option>
                      <option value="3">REDESIGN YOUR FAVORITE LOGO</option>
                    </select>
                    <div className="invalid-feedback">
                      Please select a case study!
                    </div>
                  </div>
                  <div className="mt-2 mb-3">
                    <label htmlFor="teamName" className="form-label">
                      <h5 style={{ color: "#4397C0" }}>Team Name :</h5>
                    </label>
                    <input
                      value={teamName}
                      type="text"
                      className={`form-control ${
                        errors.teamName ? "is-invalid" : ""
                      }`}
                      id="teamName"
                      name="teamName"
                      placeholder="Enter your team name"
                      required={true}
                      disabled={!isEditable}
                      onChange={(event) =>
                        this.setState({ teamName: event.target.value })
                      }
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">
                      Team Name is mandatory!
                    </div>
                  </div>
                  <div className="my-3">
                    <label htmlFor="address" className="form-label">
                      <h5 style={{ color: "#4397C0" }}>
                        Details about your invention or idea :
                      </h5>
                    </label>
                    <textarea
                      value={ideaDetails}
                      className={`form-control ${
                        errors.ideaDetails ? "is-invalid" : ""
                      }`}
                      id="details"
                      name="details"
                      rows={5}
                      required={true}
                      disabled={!isEditable}
                      placeholder="Enter details about your idea"
                      onChange={(event) =>
                        this.setState({ ideaDetails: event.target.value })
                      }
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">
                      Please provide details about your idea!
                    </div>
                  </div>
                  {isEditable && (
                    <React.Fragment>
                      <h5 style={{ color: "#4397C0" }}>
                        Select Files to Upload:
                      </h5>
                      <div className="custom-file mt-2 mb-4">
                        <input
                          type="file"
                          multiple={true}
                          className="custom-file-input border border-secondary"
                          id="validatedCustomFile"
                          required={true}
                          onChange={this.handleFileChange}
                          disabled={!isEditable}
                          accept="image/*,.doc,.docx,.txt,.pdf,.word,.rtf,.odt"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="validatedCustomFile"
                        >
                          {isNewSubmission
                            ? "Choose files..."
                            : "Add More Files..."}
                        </label>
                        <small className="form-text float-left text-muted">
                          Only pdf, text files and images are supported
                        </small>
                        <span className="float-right">
                          ({selectedFiles.length} files selected)
                        </span>
                        <div className="invalid-feedback">
                          Please select a valid file format
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <hr />
                  <h5 style={{ color: "#4397C0" }}>Team Members :</h5>
                  <div>{teamMemberDiv}</div>
                  {isEditable && (
                    <div className="input-group justify-content-center">
                      <button
                        type="button"
                        onClick={this.addTeamMember}
                        className="btn btn-secondary"
                      >
                        <FaPlus className="mr-2 mb-1" />
                        Add Team Member
                      </button>
                    </div>
                  )}
                  <hr />
                  {!isNewSubmission && (
                    <React.Fragment>
                      <h4>Uploaded Files :</h4>
                      {selectedFiles ? (
                        <div className="mt-3">{docsView}</div>
                      ) : (
                        "No files were uploaded..."
                      )}
                      <hr />
                    </React.Fragment>
                  )}
                  <div className="mt-2 mb-3">
                    <a href="#" onClick={this.showEligibility}>
                      Check your Eligibility & Judging Criteria
                    </a>
                  </div>
                  <div className="mt-2 mb-3">
                    <a href="#" onClick={this.showTermsAndConditions}>
                       Code of Conduct, Terms and Conditions
                    </a>
                  </div>
                 
                  {isNewSubmission && (
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                        disabled={isLoading}
                      >
                        {isLoading ? "Please Wait..." : "Submit Idea"}
                      </button>
                    </div>
                  )}
                  {!isNewSubmission && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <button
                          type="button"
                          onClick={() => this.setState({ isEditable: true })}
                          className="btn btn-primary btn-block btn-lg float-left"
                          disabled={isEditable}
                        >
                          <FaEdit className="mr-2 mb-2" />
                          Edit Submission
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="submit"
                          onClick={this.handleFormUpdate}
                          className="btn btn-success btn-block btn-lg float-right"
                          disabled={!isEditable}
                        >
                          <FaUpload className="mr-2 mb-2" />
                          {isLoading ? "Please Wait..." : "Update Details"}
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <Modal
            show={modalShow}
            onHide={() => this.setState({ modalShow: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span className={`${isError ? "text-danger" : "text-success"}`}>
                  {isError ? "Failed" : "Success"}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={`alert ${
                  isError ? "alert-danger" : "alert-success"
                } my-0`}
              >
                {serverMessage}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={`btn btn-info`}
                onClick={() => this.setState({ modalShow: false })}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            size="lg"
            show={eligibilityShow}
            onHide={() => this.setState({ eligibilityShow: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Eligibility and Judging Criteria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <HackathonEligibility />
            </Modal.Body>
            <Modal.Footer>
              <button
                className={`btn btn-dark`}
                onClick={() => this.setState({ termsShow: false })}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="lg"
            show={termsShow}
            onHide={() => this.setState({ termsShow: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Code of Conduct, Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <HackathonTerms />
            </Modal.Body>
            <Modal.Footer>
              <button
                className={`btn btn-dark`}
                onClick={() => this.setState({ termsShow: false })}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
          <Toast
            show={this.state.toastShow}
            onClose={() => this.setState({ toastShow: false })}
            style={{
              position: "fixed",
              zIndex: "100",
              top: 50,
              left: `${50 - 13}%`,
            }}
            className="alert-danger"
          >
            <Toast.Header className=" alert-danger">
              <FaStopCircle className="mr-1" />
              <strong className="mr-auto">Failed</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body className="alert-danger">
              Woohoo, you're reading this text in a Toast!
            </Toast.Body>
          </Toast>
        </div>
      </BaseComponent>
    );
  }
}

export default HackathonForm;
