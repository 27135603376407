import React, { Component } from "react";
import axios from "axios";
import {
  authenticate,
  isAuthenticated,
  storeUser,
} from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import logo_legasis from "../../images/logo_legasis.png";
import { Modal } from "react-bootstrap";
import BaseComponent from "../core/BaseComponent";
import { Link } from "react-router-dom";

class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      company: "",
      address: "",
      isHackathon: true,
      success: false,
      error: false,
      errors: {},
      isLoading: false,
      wasValidated: false,
      modalShow: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginRedirect = this.handleLoginRedirect.bind(this);
    this.performRedirect = this.performRedirect.bind(this);
    this.performValidation = this.performValidation.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  performValidation(event) {
    event.preventDefault();
    this.setState({ wasValidated: true, errors: {}, isLoading: true });
    let err = false;
    const {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      password,
      confirmPassword,
      errors,
    } = this.state;
    let errs = { ...errors };

    if (firstName === "" || lastName === "") {
      err = true;
      errs.firstLastName = "First Name and Last Name should not be empty.";
    } else {
      errs.firstLastName = false;
    }

    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailId.match(mailformat)) {
      errs.emailId = false;
    } else {
      err = true;
      errs.emailId =
        "The value for email field should be a valid email address.";
    }

    if (password.length < 8 || password !== confirmPassword) {
      err = true;
      if (password !== confirmPassword) {
        errs.password = "Both passwords should match";
      } else {
        errs.password = "Password should be 8 to 20 characters long";
      }
    } else {
      errs.password = false;
    }

    if (phoneNumber.match(/^([+]?\d{1,3}[- ]?)?\d{10}$/)) {
      errs.phoneNumber = false;
    } else {
      err = true;
      errs.phoneNumber =
        "Phone number should be a valid 10 digit mobile number.";
    }

    if (err) {
      this.setState({
        errors: errs,
        error: "Please provide correct value for some required fields -",
        modalShow: true,
        isLoading: false,
      });
    } else {
      this.setState({ error: false });
      this.handleSubmit();
    }
  }

  handleSubmit() {
    this.setState({ success: false, error: false });

    axios
      .post(`${API}/register`, this.state, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.result === "Failed") {
          this.setState({
            success: false,
            error: res.data.message,
            modalShow: true,
            isLoading: false,
          });
        } else if (res.data.result === "Success") {
          this.setState(
            {
              success: res.data.message,
              isLoading: false,
              error: false,
              wasValidated: false,
              firstName: "",
              lastName: "",
              emailId: "",
              phoneNumber: "",
              password: "",
              confirmPassword: "",
              company: "",
              address: "",
              modalShow: true,
            }
            //() => this.handleLoginRedirect(res.data.token)
          );
        }
      })
      .catch((err) =>
        this.setState({
          success: false,
          error:
            "Something went wrong at the server side! Please refresh the page and try again.",
          modalShow: true,
          isLoading: false,
        })
      );
  }

  handleLoginRedirect(token) {
    authenticate(token, () => {
      this.performRedirect();
    });
  }

  performRedirect() {
    if (isAuthenticated()) {
      storeUser(this.state.emailId)
        .then(() => this.props.history.push("/inventions/new"))
        .catch((err) => console.log(err));
    }
  }

  handleEmailChange(event) {
    let errs = { ...this.state.errors };
    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (event.target.value.match(mailformat)) {
      errs.emailId = false;
    } else {
      errs.emailId = true;
    }
    this.setState({ emailId: event.target.value, errors: errs });
  }

  handlePhoneNumberChange(event) {
    let errs = { ...this.state.errors };
    if (event.target.value.match(/^([+]?\d{1,3}[- ]?)?\d{10}$/)) {
      errs.phoneNumber = false;
    } else {
      errs.phoneNumber = true;
    }
    this.setState({ phoneNumber: event.target.value, errors: errs });
  }

  render() {
    const {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      password,
      confirmPassword,
      company,
      address,
      success,
      error,
      isLoading,
      errors,
      wasValidated,
      modalShow,
    } = this.state;

    const successMessage = () => {
      return (
        <div
          className="alert alert-success my-4"
          style={{
            display: success ? "" : "none",
          }}
        >
          You have been successfully registered with Flute.in. A confirmation
          mail has been sent on your email address. You can login{" "}
          <a href="#">
            <Link to="/login">here</Link>
          </a>
        </div>
      );
    };

    const failureMessage = () => {
      return (
        <div
          className="alert alert-danger my-4"
          style={{
            display: error ? "" : "none",
          }}
        >
          {error}
        </div>
      );
    };

    return (
      <BaseComponent isLoading={isLoading}>
        <div className="back-image-2">
          <div
            style={{ background: "rgb(255, 255, 255, 0)" }}
            className="container"
          >
            <div className="row justify-content-md-center">
              <div className="col-md-8 shadow-lg rounded bg-white mx-3 my-4">
                {successMessage()}
                {failureMessage()}
                <div className="row my-3">
                  <div className="col-md-2">
                    <div className="text-center">
                      <img
                        src={logo_legasis}
                        width={80}
                        height={80}
                        alt="Flute Logo"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="text-center my-4">
                      <u style={{ color: "#12789d", fontSize: "22px" }}>Register for World IP Day 2024 hackathon</u>
                    </h3>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <form
                  onSubmit={this.performValidation}
                  className={`form-group row mx-2 needs-validation`}
                  noValidate={true}
                >
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      First Name <sup className={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      value={firstName}
                      type="text"
                      className={`form-control ${
                        firstName.length > 2
                          ? "is-valid"
                          : `${wasValidated && "is-invalid"}`
                      }`}
                      id="firstName"
                      name="firstName"
                      minLength={2}
                      onChange={this.handleInputChange}
                      required={true}
                      autoFocus={true}
                    />
                    <div className="invalid-feedback">
                      Please provide first name.
                    </div>
                    <div className="valid-feedback">Looks Good!</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="lastName" className="form-label">
                      Last Name <sup className={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      value={lastName}
                      type="text"
                      className={`form-control ${
                        lastName.length > 2
                          ? "is-valid"
                          : `${wasValidated && "is-invalid"}`
                      }`}
                      id="lastName"
                      name="lastName"
                      minLength={2}
                      onChange={this.handleInputChange}
                      required={true}
                    />
                    <div className="invalid-feedback">
                      Please provide last name.
                    </div>
                    <div className="valid-feedback">Looks Good!</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="emailId" className="form-label">
                      Email <sup className={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      value={emailId}
                      type="email"
                      className={`form-control ${
                        wasValidated
                          ? errors.emailId
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      id="emailId"
                      name="emailId"
                      onChange={this.handleEmailChange}
                      required={true}
                    />
                    <div className="invalid-feedback">
                      Please provide a valid Email address.
                    </div>
                    <div className="valid-feedback">Looks Good!</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone <sup className={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      value={phoneNumber}
                      type="tel"
                      className={`form-control ${
                        wasValidated
                          ? errors.phoneNumber
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      id="phoneNumber"
                      name="phoneNumber"
                      minLength={10}
                      maxLength={15}
                      onChange={this.handlePhoneNumberChange}
                      required={true}
                    />
                    <div className="invalid-feedback">
                      Phone number should be a valid 10 digit number.
                    </div>
                    <div className="valid-feedback">Looks Good!</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="password" className="form-label">
                      Password <sup className={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      value={password}
                      type="password"
                      className={`form-control ${
                        password.length >= 1 && password.length < 8
                          ? "is-invalid"
                          : `${
                              password.length >= 8
                                ? "is-valid"
                                : `${wasValidated ? "is-invalid" : ""}`
                            }`
                      }`}
                      minLength={8}
                      maxLength={30}
                      id="password"
                      name="password"
                      onChange={this.handleInputChange}
                      required={true}
                    />
                    {password.length < 8 && (
                      <div className={"invalid-feedback"}>
                        Password should be 8 to 20 characters long.
                      </div>
                    )}
                    {password.length >= 8 && (
                      <div className="valid-feedback">OK!</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password <sup className={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      value={confirmPassword}
                      type="password"
                      className={`form-control ${
                        confirmPassword.length >= 1
                          ? password !== confirmPassword
                            ? "is-invalid"
                            : "is-valid"
                          : `${wasValidated ? "is-invalid" : ""}`
                      }`}
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={this.handleInputChange}
                      required={true}
                    />

                    <div className={"invalid-feedback"}>
                      Both passwords should match.
                    </div>
                    <div className="valid-feedback">Passwords Match!</div>
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="company" className="form-label">
                      Company Name
                    </label>
                    <input
                      value={company}
                      type="text"
                      className="form-control"
                      id="company"
                      name="company"
                      placeholder="Enter your company name"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <input
                      value={address}
                      type="text"
                      className={`form-control`}
                      id="address"
                      name="address"
                      placeholder="Enter your address (office/residential)"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group col-12 mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      disabled={isLoading}
                    >
                      {isLoading ? "Please Wait..." : "Register"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <Modal
            show={modalShow}
            onHide={() => this.setState({ modalShow: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span className={`${success ? "text-success" : "text-danger"}`}>
                  {success && "Success"}
                  {error && "Failed"}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={`alert ${
                  success ? "alert-success" : "alert-danger"
                } my-0`}
              >
                {success ? (
                  "You have been successfully registered with Flute.in. A confirmation mail has been sent on your email address."
                ) : (
                  <div>
                    {error}
                    <hr />
                    {Object.keys(errors).map((item, index) => (
                      <div key={index}>{errors[item]}</div>
                    ))}
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={`btn btn-info`}
                onClick={() => this.setState({ modalShow: false })}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </BaseComponent>
    );
  }
}

export default RegisterUser;
