import axios from "axios";
import React, { Component } from "react";
import { Modal, Toast } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import BaseComponent from "../core/BaseComponent";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEditable: false,
      userId: "",
      emailId: "",
      password: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      company: "",
      designation: "",
      address: "",
      serverMessage: "",
      newPassword: "",
      confirmPassword: "",
      changePasswordMsg: false,
      modalShow: false,
      showToast: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleProfileUpdate = this.handleProfileUpdate.bind(this);
    this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      axios
        .get(`${API}/users/id/${getCurrentUser().userId}`, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          this.setState({
            userId: res.data.userId,
            emailId: res.data.emailId,
            phoneNumber: res.data.phoneNumber,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            company: res.data.company,
            designation: res.data.designation,
            address: res.data.address,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleProfileUpdate(event) {
    event.preventDefault();

    this.setState({ isLoading: true }, () => {
      axios
        .put(`${API}/users/${this.state.userId}`, this.state, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          this.setState({
            isLoading: false,
            isError: false,
            showToast: true,
            serverMessage: "Profile details are updated successfully!",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
            isError: true,
            showToast: true,
            serverMessage: "Something went wrong! Please try again.",
          });
        });
    });
  }

  handlePasswordUpdate() {
    const { newPassword, confirmPassword } = this.state;
    if (newPassword.length >= 8) {
      if (newPassword !== confirmPassword) {
        this.setState({
          changePasswordMsg: "Passwords do not match.",
        });
        return;
      } else if (newPassword === confirmPassword) {
        this.setState({ isLoading: true, modalShow: false });
        axios
          .post(
            `${API}/updatePassword`,
            { email: this.state.emailId, password: newPassword },
            {
              headers: {
                Authorization: "Bearer " + isAuthenticated(),
              },
            }
          )
          .then((res) => {
            this.setState({
              isLoading: false,
              isError: res.data === "success" ? false : true,
              serverMessage:
                res.data === "success"
                  ? "Password has been updated successfully."
                  : "Something went wrong! Couldn't update Password.",
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading: false,
              isError: true,
              serverMessage: "Something went wrong! Please try again.",
            });
          });
      }
    } else {
      this.setState({
        changePasswordMsg: "Password should be atleast 8 characters long.",
      });
      return;
    }
  }

  render() {
    const {
      isLoading,
      isEditable,
      emailId,
      phoneNumber,
      firstName,
      lastName,
      company,
      designation,
      address,
      serverMessage,
      newPassword,
      confirmPassword,
      changePasswordMsg,
      modalShow,
      showToast,
    } = this.state;

    return (
      <BaseComponent isLoading={isLoading}>
        <div
          className="container"
          style={{
            boxShadow:
              "2px 2px 14px 0 rgb(0 0 0 / 22%), -8px -8px 18px 0 rgb(255 255 255 / 55%)",
            borderRadius: "25px",
            paddingBottom: "25px",
          }}
        >
          <div className="mt-4 row justify-content-md-center border-bottom">
            <div className="col-md-10 pt-3 pb-2 bg-white">
              <h2 id="heading" className="text-center">
                Profile Details
              </h2>
            </div>
          </div>
          <br />
          <form className="mx-3">
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
                Email
              </label>
              <div className="col-sm-4">
                <input
                  value={emailId}
                  type="text"
                  readOnly={true}
                  className="form-control-plaintext"
                  id="staticEmail"
                />
              </div>
              <div className="col-sm-4">
                <button
                  type="button"
                  onClick={() => this.setState({ modalShow: true })}
                  className="btn btn-primary"
                >
                  Change Password
                </button>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="firstName" className="col-sm-4 col-form-label">
                First Name
              </label>
              <div className="col-sm-8">
                <input
                  value={firstName}
                  disabled={!isEditable}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="lastName" className="col-sm-4 col-form-label">
                Last Name
              </label>
              <div className="col-sm-8">
                <input
                  value={lastName}
                  disabled={!isEditable}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="phoneNumber" className="col-sm-4 col-form-label">
                Phone
              </label>
              <div className="col-sm-8">
                <input
                  value={phoneNumber}
                  disabled={!isEditable}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="company" className="col-sm-4 col-form-label">
                Company
              </label>
              <div className="col-sm-8">
                <input
                  value={company}
                  disabled={!isEditable}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="company"
                  name="company"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="designation" className="col-sm-4 col-form-label">
                Designation
              </label>
              <div className="col-sm-8">
                <input
                  value={designation}
                  disabled={!isEditable}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="designation"
                  name="designation"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="address" className="col-sm-4 col-form-label">
                Address
              </label>
              <div className="col-sm-8">
                <input
                  value={address}
                  disabled={!isEditable}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                />
              </div>
            </div>
            <div className="form-group row px-3">
              {isEditable ? (
                <button
                  onClick={this.handleProfileUpdate}
                  className="btn btn-primary btn-block"
                >
                  Update Profile
                </button>
              ) : (
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    this.setState({ isEditable: true });
                  }}
                  className="btn btn-primary btn-block"
                >
                  Edit Profile Details
                </button>
              )}
            </div>
          </form>
          <div className="d-flex justify-content-center">
            <Toast
              style={{
                position: "fixed",
                top: 25,
              }}
              className="justify-self-center"
              show={showToast}
              onClose={() => this.setState({ showToast: false })}
              delay={3000}
              autohide
            >
              <Toast.Header className="alert-success">
                <FaExclamationTriangle className="mr-2" />
                <strong className="mr-auto">Message</strong>
                <small>just now</small>
              </Toast.Header>
              <Toast.Body className="alert-success">{serverMessage}</Toast.Body>
            </Toast>
          </div>
        </div>
        <Modal
          show={modalShow}
          onHide={() => this.setState({ modalShow: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-group needs-validation">
              <div className="form-group row">
                <label
                  htmlFor="newPassword"
                  className="col-sm-5 col-form-label"
                >
                  New Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    className={`form-control ${
                      newPassword.length >= 1 && newPassword.length < 8
                        ? "is-invalid"
                        : `${newPassword.length >= 8 ? "is-valid" : ""}`
                    }`}
                    id="newPassword"
                    name="newPassword"
                    required={true}
                    onChange={(event) =>
                      this.setState({ newPassword: event.target.value })
                    }
                  />
                  <div className={"invalid-feedback"}>
                    Password should be 8 to 20 characters long.
                  </div>
                  <div className="valid-feedback">OK!</div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="confirmPassword"
                  className="col-sm-5 col-form-label"
                >
                  Confirm Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    className={`form-control ${
                      confirmPassword.length >= 1
                        ? newPassword !== confirmPassword
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    required={true}
                    onChange={(event) =>
                      this.setState({ confirmPassword: event.target.value })
                    }
                  />
                  <div className={"invalid-feedback"}>
                    Both passwords should match.
                  </div>
                  <div className="valid-feedback">Passwords Match!</div>
                </div>
              </div>
              {changePasswordMsg && (
                <div className="alert alert-warning">{changePasswordMsg}</div>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={`btn btn-secondary float-left`}
              onClick={() => this.setState({ modalShow: false })}
            >
              Cancel
            </button>
            <button
              className={`btn btn-success float-right`}
              onClick={this.handlePasswordUpdate}
            >
              Update
            </button>
          </Modal.Footer>
        </Modal>
      </BaseComponent>
    );
  }
}

export default UserProfile;
