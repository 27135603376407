import React from "react";

export default function Loader() {
  return (
    <div
      style={{
        zIndex: "100",
        background: "rgb(255, 255, 255, 0.8)",
      }}
      className={`d-flex 
        position-fixed
        w-100 
        h-100 
        text-center 
        transparent 
        justify-content-center`}
    >
      <div
        style={{
          width: "3rem",
          height: "3rem",
        }}
        className="spinner-border text-primary align-self-center"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
