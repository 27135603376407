import React, { Component } from "react";
import {
  authenticate,
  signin,
  isAuthenticated,
  storeUser,
} from "../../auth/helper/AuthenticationService";
import { ISLOGINDISABLED } from "../../core/loginconfig";
import { Modal, Toast } from "react-bootstrap";
import axios from "axios";
import { API } from "../../backend";
import BaseComponent from "../core/BaseComponent";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      success: false,
      error: false,
      isLoading: false,
      user: "",
      modalShow: false,
      showToast: false,
      toastMessage: "",
      confirmEmail: "",
      passwordSuccess: false,
      passwordFailure: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.performRedirect = this.performRedirect.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  onSubmitHandler(event) {
    event.preventDefault();
    this.setState({ success: false, error: false, isLoading: true });

    signin({ username: this.state.email, password: this.state.password })
      .then((res) => {
        this.setState({
          success: true,
          toastMessage: "You have successfully logged in!",
          isLoading: false,
          showToast: true,
        });
        authenticate(res.token, () => {
          this.performRedirect();
        });
      })
      .catch((e) =>
        this.setState({
          error: true,
          toastMessage: "Please provide correct email and password!",
          success: false,
          isLoading: false,
          showToast: true,
        })
      );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  performRedirect() {
    if (isAuthenticated()) {
      storeUser(this.state.email)
        .then(() => this.props.history.push("/hackathon/case-study"))
        .catch((err) => console.log(err));
    }
  }

  forgotPassword() {
    axios
      .get(`${API}/forgotPassword?email=${this.state.confirmEmail}`)
      .then((res) => {
        if (res.data === "success") {
          this.setState({ passwordSuccess: true, passwordFailure: false });
        } else {
          this.setState({ passwordSuccess: false, passwordFailure: true });
        }
      })
      .catch((err) => {
        this.setState({ passwordFailure: true, passwordSuccess: false });
        console.log(err);
      });
  }

  componentDidMount() {
    console.log(ISLOGINDISABLED);
  }

  render() {
    const {
      success,
      error,
      isLoading,
      modalShow,
      showToast,
      toastMessage,
      confirmEmail,
      passwordSuccess,
      passwordFailure,
    } = this.state;

    return (
      <BaseComponent>
        <div className="container-fluid back-image-2">
          <div className="row justify-content-md-center">
            <div className="col-md-5 px-5">
              <div className="shadow-lg p-4 bg-body rounded bg-white my-5">
                <div className="mx-2">
                  <h2>Sign in to Flute</h2>
                  <p>Lets get your ideas patented...</p>
                  <form onSubmit={this.onSubmitHandler}>
                    <div className="input-group-lg mb-3">
                      <input
                        value={this.state.email}
                        name="email"
                        type="text"
                        className="form-control border border-secondary"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={this.handleInputChange}
                        placeholder="Enter your email address"
                        required={true}
                      />
                      <small
                        id="passwordHelpBlock"
                        className="form-text text-muted"
                      >
                        We will never share your email with anyone else.
                      </small>
                    </div>
                    <div className="input-group-lg">
                      <input
                        value={this.state.password}
                        name="password"
                        type="password"
                        className="form-control border border-secondary"
                        id="exampleInputPassword1"
                        onChange={this.handleInputChange}
                        placeholder="Enter your password"
                        required={true}
                      />
                      <small
                        id="passwordHelpBlock"
                        className="form-text text-muted"
                      >
                        Your password must be 8-20 characters long.
                      </small>
                    </div>
                    <div className="mb-3">
                      <a
                        href="#"
                        onClick={() => this.setState({ modalShow: true })}
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-lg btn-block btn-success rounded mb-2"
                      disabled={ISLOGINDISABLED === "true" || isLoading}
                    >
                      {isLoading ? "Please wait..." : "Sign in"}
                    </button>
                    <hr />
                    <div className="text-center">
                      New user?{" "}
                      <a href="#">
                        <Link to="/register">Register Here</Link>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalShow}
          onHide={() => this.setState({ modalShow: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="confirmEmail" className="form-label">
              Please provide your email address
            </label>
            <input
              value={confirmEmail}
              type="email"
              id="confirmEmail"
              className={`form-control`}
              name="confirmEmail"
              minLength={2}
              onChange={this.handleInputChange}
              required={true}
              autoFocus={true}
            />
            {passwordSuccess && (
              <div className="alert alert-success mt-2">
                We have sent you an email with your password details.
              </div>
            )}
            {passwordFailure && (
              <div className="alert alert-danger mt-2">
                Sorry we couldn't find any profile with this email address.
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className={`btn btn-success`} onClick={this.forgotPassword}>
              Confirm
            </button>
            <button
              className={`btn btn-secondary`}
              onClick={() => this.setState({ modalShow: false })}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <div className="d-flex justify-content-center">
          <Toast
            style={{
              position: "fixed",
              top: 25,
            }}
            show={showToast}
            onClose={() => this.setState({ showToast: false })}
            delay={3000}
            autohide
          >
            <Toast.Header
              className={success ? "alert-success" : "alert-danger"}
            >
              <FaExclamationTriangle className="mr-2" />
              <strong className="mr-auto">Message</strong>
              <small>just now</small>
            </Toast.Header>
            <Toast.Body className={success ? "alert-success" : "alert-danger"}>
              {toastMessage}
            </Toast.Body>
          </Toast>
        </div>
      </BaseComponent>
    );
  }
}

export default LoginComponent;
