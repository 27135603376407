import BaseComponent from "./core/BaseComponent";
import KnowledgeData from "./KnowledgeData";

function Knowledge() {
  return (
    <BaseComponent>
      <div
        className="container"
        style={{
          boxShadow:
            "2px 2px 14px 0 rgb(0 0 0 / 22%), -8px -8px 18px 0 rgb(255 255 255 / 55%)",
          borderRadius: "25px",
          paddingBottom: "25px",
        }}
      >
        <div className="mt-4 row justify-content-md-center border-bottom">
          <div className="col-md-10 pt-3 pb-2 bg-white">
            <h2 id="heading" className="text-center">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div className="faqContainer" style={{ marginTop: "25px" }}>
          <KnowledgeData
            question="1.	What are Intellectual Property Rights (IPR)?"
            answer="Intellectual Property Rights are rights given to an inventor or creator for any original creation of the human intellect such as artistic, literary, technical, or scientific creation."
          />
          <KnowledgeData
            question="2.	What are the different types of IPR?"
            answer="Patents, copyrights, trademarks, industrial designs, protection of geographical indications(GIs), IC lay-out designs, trade secrets and new plant varieties."
          />
          <KnowledgeData
            question="3.	Are IP rights territorial in nature?"
            answer="Yes, IP rights are territorial. It means that an Indian registration is valid only in India. For protection of Intellectual Property in any other country, one has to seek protection separately under the relevant law."
          />
          <KnowledgeData
            question="4.	What is a Patent?"
            answer="Patent is a statutory right granted by the respective governments. It gives one the exclusive rights and bars others from making, using, selling and importing product or process, based on the patented invention without one’s prior permission."
          />
          <KnowledgeData
            question="5.	What are the criteria of patentability?"
            answer="For an invention to be patentable, the invention must be novel, must involve an inventive step and should be capable of industrial application."
          />
          <KnowledgeData
            question="6.	When my invention cannot be patented even if it fulfills three criteria of patentability?"
            answer="An invention is not patentable if it falls under the categories of inventions that are excluded from patentability under the concerned jurisdiction. E.g. In India section 3 of Indian Patent Act defines the inventions which are not patentable."
          />
          <KnowledgeData
            question="7.	What is the validity of a Patent?"
            answer="A patent is valid for 20 years from the date of first filing of the patent application."
          />
          <KnowledgeData
            question="8.	What is industrial design/registered design?"
            answer="Industrial design refers only to the ornamental, aesthetic or overall visual appearance of a product."
          />
          <KnowledgeData
            question="9.	What is the validity of industrial designs?"
            answer="Valid for 10 years, extendible by 5 years."
          />
          <KnowledgeData
            question="10.	What is a Trademark?"
            answer="Trademark is a sign that helps distinguish the products from a particular producer or enterprise from those of its competitors."
          />
          <KnowledgeData
            question="11.	What can be registered as a Trademark?"
            answer="Trademarks may be a word or a combination of words, letters and numerals. They may also consist of drawings, symbols, 3D signs such as shape and packaging of goods, or colours used as a distinguishing feature."
          />
          <KnowledgeData
            question="12.	What is the validity of a Trademark?"
            answer="A registered trademark is valid initially for 10 years and can be renewed every 10 years."
          />
          <KnowledgeData
            question="13.	What is Copyright?"
            answer="An intangible incorporeal right granted to the author or originator of certain literary, Cinematographic film, Sound recording or other artistic production."
          />
          <KnowledgeData
            question="14.	Is it required to register a Copyright?"
            answer="In India, copyright comes into existence as soon as a work is created. However, certificate of registration of Copyright serve as a prima facie evidence in a court of law in case of any infringement."
          />
          <KnowledgeData
            question="15.	What is the validity of a copyright?"
            answer="A copyright is valid till 60 years after death of the creator."
          />
          <KnowledgeData
            question="16.	What are the benefits of registering an IPR?"
            answer="IPR enables to secure certain moral and material interests of the creator or owner of the IP. The owner can leverage the exclusive monopoly to use the registered IP and is entitled to receive materialistic benefits by licensing, leasing or selling his IP rights."
          />
          <KnowledgeData
            question="17.	What should I not do before registering my IP?"
            answer="One should not disclose their original creation or invention publicly before registering the IP to avoid losing IP rights on that creation or invention."
          />
        </div>
      </div>
    </BaseComponent>
  );
}

export default Knowledge;
