import React, { Component } from "react";
import { FaUpload } from "react-icons/fa";
import axios from "axios";
import { API } from "../../backend";
import { isAuthenticated } from "../../auth/helper/AuthenticationService";
import { Modal, Toast } from "react-bootstrap";
import BaseComponent from "../core/BaseComponent";

class EditInvention extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventionId: "",
      selectedInvention: {},
      active: 0,
      isLoading: false,
      isEditable: false,
      enableSubmitButton: false,
      serverMessage: "",
      isError: false,
      modalShow: false,
    };

    this.getInventionDetails = this.getInventionDetails.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.getInventionDetails();
  }

  getInventionDetails() {
    this.setState({ isLoading: true });
    let id = window.location.href.split("/inventions/edit/")[1];
    axios
      .get(`${API}/invention/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + isAuthenticated(),
        },
      })
      .then((res) => {
        if (res.data) {
          this.setState({
            selectedInvention: res.data,
            isLoading: false,
            isError: false,
          });
        } else {
          this.setState({
            isLoading: false,
            isError: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          isError: true,
        });
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let current = { ...this.state.selectedInvention };
    current.legasisComment = value;
    current.submissionStatus = "REVIEWED";
    this.setState({
      selectedInvention: current,
    });
  }

  submitForm() {
    this.setState({ isLoading: true, submittedDate: new Date() }, () => {
      axios
        .put(`${API}/inventions`, this.state.selectedInvention, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((response) => {
          this.setState({ step: 4, isLoading: false, modalShow: false });
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ isLoading: false });
        });
    });
  }

  render() {
    const {
      selectedInvention,
      isLoading,
      isEditable,
      enableSubmitButton,
      modalShow,
    } = this.state;

    return (
      <BaseComponent isLoading={isLoading}>
        <div
          className="container"
          style={{
            boxShadow:
              "2px 2px 14px 0 rgb(0 0 0 / 22%), -8px -8px 18px 0 rgb(255 255 255 / 55%)",
            borderRadius: "25px",
          }}
        >
          <div className="mt-4 row text-center p-3 border-bottom">
            <div className="col-4"></div>
            <div className="col-4">
              <h2 id="heading" className="text-center">
                Edit Invention
              </h2>
            </div>
            <div className="col-4"></div>
          </div>
          <div className="row border-bottom">
            <div className="border my-3 p-3 rounded">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Invention Title</label>
                  <textarea
                    value={selectedInvention.inventionTitle}
                    name="inventionTitle"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Invention Subject Area
                  </label>
                  <textarea
                    value={selectedInvention.inventionArea}
                    name="inventionArea"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Invention Description
                  </label>
                  <textarea
                    value={selectedInvention.inventionDescription}
                    name="inventionDescription"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Is this form being submitted to initiate patent filing
                    process?
                  </label>
                  <textarea
                    value={selectedInvention.question1}
                    name="question1"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Are you submitting this form to report a knowhow which you
                    are unsure if it should be patented and therefore need
                    advise?
                  </label>
                  <textarea
                    value={selectedInvention.question2}
                    name="question2"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label>
                    If yes, where do you think the patent needs to be filed?
                  </label>
                  <textarea
                    value={selectedInvention.question3}
                    name="question3"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label>Simple Indian patent or PCT?</label>
                  <textarea
                    value={selectedInvention.question4}
                    name="question4"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label>
                    If the know how is not patented, do you think this can be
                    treated as trade secret? In case of trade secret, VP will
                    share information on ‘need to know basis’ and under
                    ‘obligation’ only.
                  </label>
                  <textarea
                    value={selectedInvention.question5}
                    name="question5"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label>
                    Is this case related to any project listed on OPTIVA/ JIRA,
                    if yes, what is the reference number?
                  </label>
                  <textarea
                    value={selectedInvention.question6}
                    name="question6"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label>
                    Is this case related to any Innovation proposal on the
                    EUREKA, if yes, what is the reference number?
                  </label>
                  <textarea
                    value={selectedInvention.question7}
                    name="question7"
                    className="form-control"
                    aria-describedby="emailHelp"
                    disabled="true"
                  />
                </div>
                <div className="form-group">
                  <label>Legasis Comments</label>
                  <textarea
                    value={selectedInvention.legasisComment}
                    name="question8"
                    className="form-control"
                    aria-describedby="emailHelp"
                    onChange={this.handleInputChange}
                  />
                </div>
              </form>
              <div className="row mt-3">
                <button
                  type="submit"
                  onClick={() => this.setState({ modalShow: true })}
                  className="btn btn-success btn-block btn-lg float-right"
                >
                  <FaUpload className="mr-2 mb-2" />
                  {isLoading ? "Please Wait..." : "Update Comment"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => this.setState({ modalShow: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Update Comments
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Do you want to Update the Comment?</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={`btn btn-secondary float-left`}
              onClick={() => this.setState({ modalShow: false })}
            >
              Close
            </button>
            <button
              className={`btn btn-success float-right`}
              onClick={this.submitForm}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </BaseComponent>
    );
  }
}

export default EditInvention;
