import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser, isAuthenticated } from './AuthenticationService';

const AdminRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated() && getCurrentUser().role === "ROLE_ADMIN" ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

export default AdminRoute;