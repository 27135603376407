import React, { Component } from "react";
import FirstTab from "./FirstTab";
import SecondTab from "./SecondTab";
import ThirdTab from "./ThirdTab";
import { FaLightbulb, FaPencilAlt, FaCheck } from "react-icons/fa";
import axios from "axios";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import Loader from "../core/Loader";
import BaseComponent from "../core/BaseComponent";

class InventionWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      inventionTitle: "",
      inventionArea: "",
      inventionDescription: "",
      selectedFiles: [],
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      question7: "",
      userName: "",
      submittedDate: "",
      isLoading: false,
    };
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  componentDidMount() {
    this.setState({ userName: getCurrentUser().emailId });
  }

  handleStepChange(step) {
    this.setState({ step });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleFileChange(event) {
    this.setState({ selectedFiles: event.target.files });
    // console.log(event.target.files,'sf')
  }

  submitForm() {
    this.setState({ isLoading: true, submittedDate: new Date() });

    let formData = new FormData();
    formData.append("inventionTitle", this.state.inventionTitle);
    formData.append("inventionArea", this.state.inventionArea);
    formData.append("inventionDescription", this.state.inventionDescription);
    formData.append("question1", this.state.question1);
    formData.append("question2", this.state.question2);
    formData.append("question3", this.state.question3);
    formData.append("question4", this.state.question4);
    formData.append("question5", this.state.question5);
    formData.append("question6", this.state.question6);
    formData.append("question7", this.state.question7);
    formData.append("userName", this.state.userName);
    formData.append("submittedDate", this.state.submittedDate);
    for (let i = 0; i < this.state.selectedFiles.length; i++) {
      formData.append(
        "selectedFiles",
        this.state.selectedFiles[i],
        this.state.selectedFiles[i].name
      );
    }

    axios
      .post(`${API}/inventions`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + isAuthenticated(),
        },
      })
      .then((response) => {
        console.log(response);
        this.setState({ step: 4, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { step, isLoading, selectedFiles } = this.state;

    return (
      <BaseComponent isLoading={isLoading}>
        <div id="wizard" className="container">
          <div className="mt-4 row align-self-center">
            <div className="col-2"></div>
            <div className="col-8">
              <br />
              <h2 id="heading" className="text-center">
                Submit Your Idea
              </h2>
              <p className="text-center">
                Please fill the details of your invention below
              </p>
              <ul id="progressbar">
                <li className={`${step >= 1 ? "active" : ""}`} id="account">
                  <span>
                    <FaLightbulb />
                  </span>
                  <strong>Step 1</strong>
                </li>
                <li className={`${step >= 2 ? "active" : ""}`} id="personal">
                  <span>
                    <FaPencilAlt />
                  </span>
                  <strong>Step 2</strong>
                </li>
                <li className={`${step >= 3 ? "active" : ""}`} id="confirm">
                  <span>
                    <FaPencilAlt />
                  </span>
                  <strong>Step 3</strong>
                </li>
                <li className={`${step >= 4 ? "active" : ""}`} id="complete">
                  <span>
                    <FaCheck />
                  </span>
                  <strong>Complete</strong>
                </li>
              </ul>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  style={{
                    width: `${step * 25}%`,
                  }}
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <br />
              <section className="tab shadow p-4 mb-5 bg-body rounded">
                {step === 1 && (
                  <FirstTab
                    handleInputChange={this.handleInputChange}
                    oldValues={this.state}
                    step={step}
                    handleStepChange={this.handleStepChange}
                  />
                )}
                {step === 2 && (
                  <SecondTab
                    handleInputChange={this.handleInputChange}
                    oldValues={this.state}
                    step={step}
                    handleStepChange={this.handleStepChange}
                  />
                )}
                {step === 3 && (
                  <ThirdTab
                    handleInputChange={this.handleInputChange}
                    oldValues={this.state}
                    selectedFiles={selectedFiles}
                    step={step}
                    handleFileChange={this.handleFileChange}
                    handleStepChange={this.handleStepChange}
                    submitForm={this.submitForm}
                  />
                )}
                {step === 4 && (
                  <div className="">
                    <div className="row">
                      <div className="col-7">
                        <h4 className="text-left">Complete:</h4>
                      </div>
                      <div className="col-5">
                        <h4 className="text-right">Step 4 - 4</h4>
                      </div>
                    </div>
                    <h2 className="purple-text text-center">
                      <strong>SUCCESS !</strong>
                    </h2>
                    <br />
                    <div class="row justify-content-center">
                      <div class="col-3">
                        <img
                          src="https://i.imgur.com/GwStPmg.png"
                          className="fit-image"
                          alt="Checkmark"
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row justify-content-center">
                      <div className="col-7 text-center">
                        <h5 className="purple-text text-center">
                          You Have Successfully Submitted Your Idea
                        </h5>
                        <button
                          onClick={() => this.props.history.push("/inventions")}
                          type="button"
                          className="m-2 btn btn-md btn-success"
                        >
                          View My Submissions
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default InventionWizard;
