import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { FaArrowRight, FaUserAlt } from "react-icons/fa";
import {
  signout,
  isAuthenticated,
  getCurrentUser,
} from "../../auth/helper/AuthenticationService";
import logo_flute from "../../images/logo_flute.jpg";
import OutsideClickHandler from "react-outside-click-handler";

const currentTab = (history, path) => {
  if (history.location.pathname.includes(path)) {
    return {
      color: "orange",
      backgroundColor: "#f8f9fa",
    };
  } else {
    return { color: "black" };
  }
};

const NavBar = ({ history }) => {
  const [dropdwonVisible, setdropdownVisible] = useState(false);
  const [inventionDropDown, setInventionDropDown] = useState(false);

  function toggleNavDropDown() {
    setdropdownVisible(!dropdwonVisible);
  }

  function toggleInventionDropdown() {
    setInventionDropDown(!inventionDropDown);
  }

  return (
    <nav className="shadow-sm navbar navbar-expand-lg bg-white py-0 my-0">
      <Link to="/">
        <img src={logo_flute} width="120" height="60" alt="Flute Logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {isAuthenticated() && getCurrentUser().role === "ROLE_ADMIN" && (
            <li className="nav-item" style={currentTab(history, "/search")}>
              <Link
                className="nav-link py-4"
                style={currentTab(history, "/search")}
                to="/search"
              >
                Search Inventions
              </Link>
            </li>
          )}

          {isAuthenticated() && getCurrentUser().role === "ROLE_USER" && (
            <li
              className="nav-item"
              style={currentTab(history, "/hackathon/case-study")}
            >
              <Link
                className="nav-link py-4"
                style={currentTab(history, "/hackathon/case-study")}
                to="/hackathon/case-study"
              >
                Hackathon
              </Link>
            </li>
          )}

          {isAuthenticated() && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setInventionDropDown(false);
              }}
            >
              <li
                onClick={toggleInventionDropdown}
                className="nav-item dropdown"
                style={currentTab(history, "/inventions")}
              >
                <Link
                  className="nav-link py-4 dropdown-toggle"
                  role="button"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-haspopup="true"
                  style={currentTab(history, "/inventions")}
                  to="#"
                >
                  Inventions
                </Link>
                <ul
                  className={`dropdown-menu py-0 my-0 ${
                    inventionDropDown ? "show" : ""
                  }`}
                  aria-labelledby="navbarDropdown"
                >
                  <li className="border-bottom my-0">
                    <Link
                      className="btn-outline-light dropdown-item py-2"
                      to="/inventions/new"
                      style={currentTab(history, "/inventions/new")}
                    >
                      New Invention
                    </Link>
                  </li>

                  <li className="border-bottom my-0">
                    <Link
                      className="btn-outline-light dropdown-item py-2"
                      to="/inventions/my"
                      style={currentTab(history, "/inventions/my")}
                    >
                      My Inventions
                    </Link>
                  </li>

                  {getCurrentUser().role === "ROLE_ADMIN" && (
                    <li className="my-0">
                      <Link
                        style={currentTab(history, "/inventions/all")}
                        className="btn-outline-light dropdown-item py-2"
                        to="/inventions/all"
                      >
                        All Inventions
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </OutsideClickHandler>
          )}

          {isAuthenticated() && getCurrentUser().role === "ROLE_ADMIN" && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setdropdownVisible(false);
              }}
            >
              <li
                onClick={toggleNavDropDown}
                className="nav-item dropdown"
                style={currentTab(history, "/hackathon")}
              >
                <Link
                  className="nav-link py-4 dropdown-toggle"
                  role="button"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-haspopup="true"
                  style={currentTab(history, "/hackathon")}
                  to="#"
                >
                  Hackathon
                </Link>
                <ul
                  className={`dropdown-menu py-0 ${
                    dropdwonVisible ? "show" : ""
                  }`}
                  aria-labelledby="navbarDropdown"
                >
                  <li className="border-bottom my-0">
                    <Link
                      className="btn-outline-light dropdown-item py-2"
                      to="/hackathon/case-study"
                      style={currentTab(history, "/hackathon/case-study")}
                    >
                      Hackathon Form
                    </Link>
                  </li>
                  <li className="my-0">
                    <Link
                      className="btn-outline-light dropdown-item py-2"
                      to="/hackathon/all"
                      style={currentTab(history, "/hackathon/all")}
                    >
                      Hackathon
                    </Link>
                  </li>
                </ul>
              </li>
            </OutsideClickHandler>
          )}

          {isAuthenticated() && getCurrentUser().role === "ROLE_ADMIN" && (
            <li className="nav-item" style={currentTab(history, "/users")}>
              <Link
                className="nav-link py-4"
                style={currentTab(history, "/users")}
                to="/users"
              >
                All Users
              </Link>
            </li>
          )}

          {isAuthenticated() && (
            <li className="nav-item" style={currentTab(history, "/knowledge")}>
              <Link
                className="nav-link py-4"
                style={currentTab(history, "/knowledge")}
                to="/knowledge"
              >
                Knowledge
              </Link>
            </li>
          )}
        </ul>

        <form className="form-inline my-2 my-lg-0">
          {isAuthenticated() && (
            <span
              id="currentUserBlock"
              onClick={() => history.push("/user/profile")}
              className="py-2 pr-2 mx-2 border-left border-dark alert-warning"
              role="button"
            >
              <FaUserAlt className="mx-2" />
              <u>
                <i>{getCurrentUser().emailId}</i>
              </u>
            </span>
          )}
          {isAuthenticated() && (
            <button
              onClick={() => {
                signout(() => history.push("/login"));
              }}
              className="btn btn-outline-secondary my-2 my-sm-0"
              type="button"
            >
              <span className="font-weight-bold">Sign Out</span>
            </button>
          )}
          {!isAuthenticated() && (
            <React.Fragment>
              <button
                onClick={() => {
                  history.push("/login");
                }}
                className="custom-button btn btn-light my-2 my-sm-0 mx-2"
                type="button"
              >
                <span className="p-2 font-weight-bold">Login</span>
              </button>
              <span className="mr-2">/</span>
            </React.Fragment>
          )}
          {!isAuthenticated() && (
            <button
              onClick={() => {
                history.push("/register");
              }}
              className="custom-button btn btn-outline-success"
              type="button"
            >
              <span className="p-2 font-weight-normal">REGISTER</span>
              <FaArrowRight className="mb-1" />
            </button>
          )}
        </form>
      </div>
    </nav>
  );
};

export default withRouter(NavBar);
