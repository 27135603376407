import {API} from "../../backend";
import axios from "axios";
// API means; http://localhost:8080/api/

export const signup = user => {
    return fetch(`${API}/register`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const signin = user => {
    return fetch(`${API}/authenticate`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const authenticate = (data, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("jwt", JSON.stringify(data));
        next();
    }
};

export const signout = next => {
    if (typeof window !== "undefined") {
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        next();
    }
};

export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("jwt"));
    } else {
        return false;
    }
}

export const storeUser = (username) => {
    if (typeof window !== "undefined") {
        return axios.get(`${API}/users/${username}`, {
            headers: {
                Accept: "application/json",
                "Authorization": "Bearer " + isAuthenticated()
            }
        })
        .then(response => {
            localStorage.setItem("user", JSON.stringify(response.data));
        })
        .catch(err => console.log(err));
    }
}

export const getCurrentUser = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("user"));
    } else {
        return false;
    }
}