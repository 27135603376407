import React from "react";

function HackathonEligibility() {
  return (
    <div className="px-3">
      <h4>ELIGIBILITY</h4>
      <div className="px-4">
        <ul>
          <li className="mb-2">
              Individuals who are citizens of India as of the time of entry.
          </li>
          <li className="mb-2">
              Hackathon is open for individuals, independent groups of people, employees of a company registered in India.
          </li>
          <li className="mb-2">A maximum of 5 people can form a team.</li>
          <li className="mb-2">One idea can be submitted per team.</li>
          <li className="mb-2">
              Each team should appoint one individual to enter the submission on their behalf.
          </li>
        </ul>
      </div>
      <hr />
      
      <h4>JUDGING CRITERIA</h4>
      <div className="px-4">
        <ul>
          <li className="mb-2">
              The ideas will be evaluated by a panel of expert judges, some of which will be members of Legasis senior management, along with other authoritative figures from the Legal domain.
          </li>
          <li className="mb-2">
              Evaluation criteria will include novelty of the idea, complexity, clarity and details in the prescribed format, feasibility, practicability, sustainability, scale of impact and potential for future work progression.
          </li>
          <li className="mb-2">
             Teams will be judged on below mentioned four criteria. Judges will weigh the criteria equally. During judging, participants should try to describe what they did for each criterion in their case study.
          </li>
          <li className="mb-2">
              These criteria will guide judges but ultimately judges are free to make decisions based on their gut feeling of which hacks are the most impressive and most deserving.
          </li>
          <li className="mb-2">
            <strong>TECHNOLOGY:</strong> How technically impressive was the hack? Was the technical problem the team tackled difficult? Did they use a particularly clever technique or did they use many different components? Did the technology involved make you go &quot;Wow&quot;?
          </li>
          <li className="mb-2">
            <strong>INNOVATION:</strong> What new have you applied to the hack? Any new technique, process etc.
          </li>
          <li className="mb-2">
            <strong>COMPLETION:</strong> Does the hack work? Did the team achieve everything they wanted?
          </li>
          <li className="mb-2">
            <strong>INDUSTRIAL APPLICABILITY:</strong> How is the idea utilized in the industry?
          </li>
        </ul>
      </div>
      <hr />     
    </div>
  );
}

export default HackathonEligibility;