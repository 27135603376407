import React, { Component } from "react";
import Loader from "./Loader";
import NavBar from "./NavBar";

class BaseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="vh-100">
        {this.props.isLoading && <Loader />}
        <NavBar />
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default BaseComponent;
