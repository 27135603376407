import React, { Component } from "react";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../auth/helper/AuthenticationService";
import { API } from "../../backend";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { FaEdit, FaUpload } from "react-icons/fa";
import Loader from "../core/Loader";
import BaseComponent from "../core/BaseComponent";
import rubbish from "../../images/rubbish.png";

class InventionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentEntryDocs: [],
      selectedInvention: {},
      active: 0,
      isLoading: false,
      isEditable: false,
      enableSubmitButton: false,
      serverMessage: "",
      isError: false,
      modalShow: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleEntryClick = this.handleEntryClick.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      axios
        .get(
          `${API}/inventions${
            getCurrentUser().role === "ROLE_USER"
              ? "/" + getCurrentUser().emailId
              : ""
          }`,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + isAuthenticated(),
            },
          }
        )
        .then((res) => {
          this.setState({ items: res.data, isLoading: false }, () => this.handleEntryClick(0));
          if (res.data.length !== 0)
            this.setState({ selectedInvention: res.data[0] });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    });
  }

  toggleItems(position) {
    if (this.state.active === position) {
      this.setState({ active: null });
    } else {
      this.setState({
        active: position,
        isEditable: false,
        enableSubmitButton: false,
        selectedInvention: this.state.items[position],
      });
    }
  }

  colorChange(position) {
    if (this.state.active === position) {
      return "#e2e3e5";
    }
    return "";
  }

  handleEntryClick(index) {
    console.log(this.state.items,index);
    if(this.state.items.length>0){
        this.setState({ isLoading: true }, () => {
          axios
            .get(`${API}/inventions/docs/${this.state.items[index].id}`, {
              headers: {
                Authorization: "Bearer " + isAuthenticated(),
              },
            })
            .then((res) => {
              console.log(res);
              this.setState({ currentEntryDocs: res.data, isLoading: false });
            })
            .catch((err) => {
              this.setState({ isLoading: false });
              console.log(err);
            });
        });
        this.setState({ currentEntry: this.state.items[index] });
    }
  }

  handleFileDelete(fileId) {
    this.setState({ isLoading: true }, () => {
      axios
        .delete(`${API}/inventions/docs/${fileId}`, {
          headers: {
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((res) => {
          console.log(res);
          let docs = this.state.currentEntryDocs.filter(
            (file) => file.id !== fileId
          );
          this.setState({ currentEntryDocs: docs, isLoading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    });
  }

  submitForm() {
    this.setState({ isLoading: true }, () => {
      axios
        .put(`${API}/inventions`, this.state.selectedInvention, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated(),
          },
        })
        .then((response) => {
          this.setState({
            isError: false,
            modalShow: true,
            isLoading: false,
            serverMessage: "Your submission has been updated successfully!",
          });
        })
        .catch(function (error) {
          console.log(error);
          this.setState({
            isError: true,
            modalShow: true,
            isLoading: false,
            serverMessage: "Something went wrong!",
          });
        });
    });
  }

  handleInputChange(event) {
    console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let oldObj = this.state.selectedInvention;
    oldObj[name] = value;
    this.setState({ selectedInvention: oldObj });
  }

  render() {
    const {
      items,
      currentEntryDocs,
      selectedInvention,
      isLoading,
      isEditable,
      enableSubmitButton,
      serverMessage,
      isError,
      modalShow,
    } = this.state;
    let itemsList = "You haven't submitted any inventions yet";

    if (typeof items === "object") {
      itemsList = items.map((item, index) => {
        return (
          <div
            key={item.id}
            style={{ background: this.colorChange(index), cursor: "pointer" }}
            onClick={() => {
              this.toggleItems(index);
              this.handleEntryClick(index);
            }}
            className="my-3 px-3 py-2 border rounded"
          >
            <div className="row px-1">
              <span className="font-weight-bold mr-2">Invention Title: </span>
              {item.inventionTitle}
            </div>
            <div className="row px-1">
              <span className="font-weight-bold mr-2">Submitted At: </span>
              {item.submittedDate}
            </div>
          </div>
        );
      });
    }

    let docsView = currentEntryDocs.map((item) => {
      return (
        <div
          key={item.id}
          style={{
            borderLeftWidth: "2px",
            borderLeftColor: "black",
            borderLeftStyle: "solid",
          }}
          className="py-2 px-2 my-2 alert-info"
        >
          <span>
            {item.title} |{" "}
            <a href={`${API}/inventions/docs/download/${item.id}`}>Download</a>
          </span>
          <span className="float-right">
            <img
              src={rubbish}
              onClick={() => this.handleFileDelete(item.id)}
              alt="Delete"
              className="p-0 m-0"
              title="Delete File"
              style={{
                verticalAlign: "top",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      );
    });

    return (
      <BaseComponent isLoading={isLoading}>
        <div
          className="container"
          style={{
            boxShadow:
              "2px 2px 14px 0 rgb(0 0 0 / 22%), -8px -8px 18px 0 rgb(255 255 255 / 55%)",
            borderRadius: "25px",
          }}
        >
          <Modal
            show={modalShow}
            onHide={() => this.setState({ modalShow: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span className={`${isError ? "text-danger" : "text-success"}`}>
                  {isError ? "Failed" : "Success"}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={`alert ${
                  isError ? "alert-danger" : "alert-success"
                } my-0`}
              >
                {serverMessage}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={`btn btn-info`}
                onClick={() => this.setState({ modalShow: false })}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>

          <div className="mt-4 row text-center p-3 border-bottom">
            <div className="col-4"></div>
            <div className="col-4">
              <h2 id="heading" className="text-center">
                Submitted Ideas
              </h2>
            </div>
            <div className="col-4"></div>
          </div>
          <div className="row border-bottom">
            <div
              className="col-5"
              style={{ overflowY: "scroll", height: "470px" }}
            >
              {itemsList}
            </div>
            <div
              className="col-7"
              style={{ overflowY: "scroll", height: "470px" }}
            >
              <div className="border my-3 p-3 rounded">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Invention Title</label>
                    <textarea
                      value={selectedInvention.inventionTitle}
                      name="inventionTitle"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Invention Subject Area
                    </label>
                    <textarea
                      value={selectedInvention.inventionArea}
                      name="inventionArea"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Invention Description
                    </label>
                    <textarea
                      value={selectedInvention.inventionDescription}
                      name="inventionDescription"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Is this form being submitted to initiate patent filing
                      process?
                    </label>
                    <textarea
                      value={selectedInvention.question1}
                      name="question1"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Are you submitting this form to report a knowhow which you
                      are unsure if it should be patented and therefore need
                      advise?
                    </label>
                    <textarea
                      value={selectedInvention.question2}
                      name="question2"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      If yes, where do you think the patent needs to be filed?
                    </label>
                    <textarea
                      value={selectedInvention.question3}
                      name="question3"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Simple Indian patent or PCT?</label>
                    <textarea
                      value={selectedInvention.question4}
                      name="question4"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      If the know how is not patented, do you think this can be
                      treated as trade secret? In case of trade secret, VP will
                      share information on ‘need to know basis’ and under
                      ‘obligation’ only.
                    </label>
                    <textarea
                      value={selectedInvention.question5}
                      name="question5"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Is this case related to any project listed on OPTIVA/
                      JIRA, if yes, what is the reference number?
                    </label>
                    <textarea
                      value={selectedInvention.question6}
                      name="question6"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Is this case related to any Innovation proposal on the
                      EUREKA, if yes, what is the reference number?
                    </label>
                    <textarea
                      value={selectedInvention.question7}
                      name="question7"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled={!isEditable}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <h5>Uploaded Files</h5>
                  {currentEntryDocs.length > 0
                    ? docsView
                    : "No files were uploaded..."}
                  <hr />
                </form>
                
                <div className="row mt-3">
                  <div className="col-md-6">
                    {!isEditable && (
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({
                            isEditable: true,
                            enableSubmitButton: true,
                          })
                        }
                        className="btn btn-primary btn-block btn-lg float-left"
                      >
                        <FaEdit className="mr-2 mb-2" />
                        Edit Invention
                      </button>
                    )}
                  </div>
                  <div className="col-md-6">
                    {enableSubmitButton && (
                      <button
                        type="submit"
                        onClick={this.submitForm}
                        className="btn btn-success btn-block btn-lg float-right"
                      >
                        <FaUpload className="mr-2 mb-2" />
                        {isLoading ? "Please Wait..." : "Update Details"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default InventionList;
